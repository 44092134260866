import React, { createRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import CheckboxMarkedCircleOutlineIcon from 'mdi-material-ui/CheckboxMarkedCircleOutline';
import InformationOutlineIcon from 'mdi-material-ui/InformationOutline';
import AlertOctagonOutlineIcon from 'mdi-material-ui/AlertOctagonOutline';
import AlertOutlineIcon from 'mdi-material-ui/AlertOutline';
import { RdtSnackbarManager } from './RdtSnackbarManager';

const useStyles = makeStyles(() => ({
    noSelect: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    successToast: {
        color: 'rgb(183, 223, 185) !important',
        backgroundColor: 'rgb(7, 17, 7) !important'
    },
    infoToast: {
        color: 'rgb(166, 213, 250) !important',
        backgroundColor: 'rgb(3, 14, 24) !important'
    },
    warningToast: {
        color: 'rgb(255, 213, 153) !important',
        backgroundColor: 'rgb(25, 15, 0) !important'
    },
    errorToast: {
        color: 'rgb(250, 179, 174) !important',
        backgroundColor: 'rgb(24, 6, 5) !important'
    },
    successIcon: {
        color: '#4caf50',
        marginRight: '10px'
    },
    infoIcon: {
        color: '#2196f3',
        marginRight: '10px'
    },
    warningIcon: {
        color: '#ff9800',
        marginRight: '10px'
    },
    errorIcon: {
        color: '#f44336',
        marginRight: '10px'
    }
}));

export const RdtSnackbarProvider = props => {

    const classes = useStyles();

    const notistackRef = createRef();
    const onClickDismiss = key => () => {
        notistackRef.current.closeSnackbar(key);
    }

    return (
        <SnackbarProvider
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            maxSnack={7}
            ref={notistackRef}
            iconVariant={{
                success: <CheckboxMarkedCircleOutlineIcon fontSize='small' className={classes.successIcon} />,
                info: <InformationOutlineIcon fontSize='small' className={classes.infoIcon} />,
                error: <AlertOctagonOutlineIcon fontSize='small' className={classes.errorIcon} />,
                warning: <AlertOutlineIcon fontSize='small' className={classes.warningIcon} />
            }}
            classes={{
                root: classes.noSelect,
                variantSuccess: classes.successToast,
                variantInfo: classes.infoToast,
                variantError: classes.errorToast,
                variantWarning: classes.warningToast
            }}
            action={(key) => (
                <IconButton color='inherit' onClick={onClickDismiss(key)} size='small'>
                    <CloseIcon fontSize='small' />
                </IconButton>
            )}>

            <RdtSnackbarManager />
            {props.children}

        </SnackbarProvider>
    );
};