import React, { useCallback, useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import UserLoggedInIcon from '@material-ui/icons/Person';
import UserLoggedOutIcon from '@material-ui/icons/PersonOutline';
import * as authStore from '../../redux/authStore';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';

const useStyles = makeStyles(() => ({
    loggedInColor: {
        color: '#ffffff'
    },
    loggedOutColor: {
        color: '#ffffff'
    },
    divider: {
        margin: '8px 0'
    }
}));

export const AuthUserManager = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const hasCheckedUserInfo = useSelector(state => authStore.selectors.selectHasCheckedUserInfo(state));

    const getAuthUserInfo = useCallback(() => dispatch(authStore.actionCreators.getAuthUserInfo()), [dispatch]);
    const loginUser = useCallback(() => dispatch(authStore.actionCreators.loginUser()), [dispatch]);
    const logoutUser = useCallback(() => dispatch(authStore.actionCreators.logoutUser()), [dispatch]);

    const [intervalId, setIntervalId] = useState(0);

    useEffect(() => {
        getAuthUserInfo();
    }, [getAuthUserInfo]);

    useEffect(() => {
        // Poll every five minutes for session logout
        // TODO: Replace with SignalR
        if (isLoggedIn && intervalId === 0) {
            let id = window.setInterval(() => {
                if (isLoggedIn) {
                    getAuthUserInfo();
                }
                if (!isLoggedIn && intervalId && intervalId !== 0) {
                    window.clearInterval(intervalId);
                    setIntervalId(0);
                }
            }, 300000);
            setIntervalId(id);
        }
    }, [getAuthUserInfo, isLoggedIn, intervalId]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogin = () => {
        if (!isLoggedIn && hasCheckedUserInfo) {
            loginUser();
        }
        handleClose();
    }

    const handleMyAccount = () => {
        navigate('/app/account');
        handleClose();
    }

    const handleLogout = () => {
        if (isLoggedIn && hasCheckedUserInfo) {
            logoutUser();
        }
        handleClose();
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                {isLoggedIn && <UserLoggedInIcon className={classes.loggedInColor} />}
                {!isLoggedIn && <UserLoggedOutIcon className={classes.loggedOutColor} />}
            </IconButton>
            <Menu
                id='auth-user-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
                <Divider className={classes.divider} />
                {!isLoggedIn && <MenuItem onClick={handleLogin}>Log In</MenuItem>}
                {isLoggedIn && <MenuItem onClick={handleLogout}>Log Out</MenuItem>}
            </Menu>
        </div >
    );
};