import * as actionTypes from '../actionTypes';

export const actionCreators = {

    setIsLoadingUsers: (isLoadingUsers) => dispatch => {
        dispatch({ type: actionTypes.setIsLoadingUsers, isLoadingUsers });
    },

    getUsers: () => dispatch => {
        dispatch({ type: actionTypes.getUsersSaga });
    },

    receiveUsers: (records) => dispatch => {
        dispatch({ type: actionTypes.receiveUsers, records });
    },

    setSelectedUserId: (selectedUserId) => dispatch => {
        dispatch({ type: actionTypes.setSelectedUserId, selectedUserId });
    },

    setIsLoadErrorUsers: (isLoadErrorUsers, loadErrorMessageUsers) => dispatch => {
        dispatch({ type: actionTypes.setIsLoadErrorUsers, isLoadErrorUsers, loadErrorMessageUsers });
    }

}