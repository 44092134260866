import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PageContentPresenter } from '../container/PageContentPresenter';
import Box from '@material-ui/core/Box';
import * as contextStore from '../../redux/contextStore';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import DataGrid from '@tns/react-data-grid';
//import './react-data-grid.css';
//import AutoSizer from 'react-virtualized-auto-sizer';
import { QualifyingOrderTableToolbarRow } from './QualifyingOrderTableToolbarRow';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    },
    tableRow: {
        flexGrow: '1',
        minWidth: '500px'
    },
    newRow: {
        color: '#ffffff44',
        fontStyle: 'italic'
    }
}));

export const QualifyingOrderPage = () => {

    const classes = useStyles();

    const isLoadedDrivers = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesDriver'));
    const isLoadingDrivers = useSelector(state => recordStore.selectors.selectIsLoading(state, 'rdtSeriesDriver'));
    const isLoadedClassDrivers = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesYearEventClassDriver'));
    const isLoadingClassDrivers = useSelector(state => recordStore.selectors.selectIsLoading(state, 'rdtSeriesYearEventClassDriver'));
    const syecd = useSelector(state => contextStore.selectors.selectClassDriversForSelectedSeriesYearEventClass(state));
    const syecId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass'));

    const [tableData, setTableData] = useState([]);
    const [dirtyRows, setDirtyRows] = useState([]);

    useEffect(() => {
        if (isLoadedDrivers && !isLoadingDrivers && isLoadedClassDrivers && !isLoadingClassDrivers) {
            const newData = syecd.map(x => { return { ...x }; });
            newData.sort((a, b) => a.qualifyingPosition - b.qualifyingPosition);
            setTableData(newData);
        }
    }, [syecd, isLoadedDrivers, isLoadingDrivers, isLoadedClassDrivers, isLoadingClassDrivers]);

    const handleRowsUpdated = ({ fromRow, toRow, updated }) => {
        const rows = tableData.map(x => { return { ...x }; });
        let dirty = dirtyRows.map(x => { return { ...x }; });
        const start = Math.min(fromRow, toRow);
        const end = Math.max(fromRow, toRow);

        for (let i = start; i <= end; i++) {
            let rowToUpdate = rows[i];
            const updatedRow = { ...rowToUpdate, ...updated };
            rows[i] = updatedRow;
            dirty = dirty.filter(x => x.id !== updatedRow.id);
            dirty.push({ ...updatedRow });
            setDirtyRows(dirty);
        }
        setTableData(rows);
    }

    const columns = [
        {
            key: 'driverCode',
            name: 'Driver Code'
        },
        {
            key: 'fullName',
            name: 'Full Name'
        },
        {
            key: 'qualifyingPosition',
            name: 'Position',
            editable: true
        },
        {
            key: 'qualifyingTime',
            name: 'ET',
            editable: true
        },
        {
            key: 'qualifyingSpeed',
            name: 'Speed',
            editable: true
        }
    ];

    return (
        <PageContentPresenter showBreadcrumbs={true} selectionRequiredCondition={syecId} selectionRequiredText={'Please select an event class'}>
            <Box className={classes.container}>
                <QualifyingOrderTableToolbarRow isDirty={dirtyRows.length > 0} tableData={dirtyRows} />
                <Box className={classes.tableRow}>
                    <DataGrid
                        enableCellSelect={true}
                        rowKeyGetter='id'
                        onRowsChange={handleRowsUpdated}
                        cellNavigationMode='changeRow'
                        columns={columns}
                        rows={tableData} />
                </Box>
            </Box>
        </PageContentPresenter>
    );
};