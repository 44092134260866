import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import * as recordStore from '../recordStore';
import * as driverStore from '../driverStore';

const validateDriverCandidateDriverCodeCell = createCachedSelector(
    [
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeries'),
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesDriver'),
        driverStore.selectors.selectDriverCandidates,
        (_, candidateRow) => candidateRow
    ],
    (seriesId, seriesDrivers, candidates, candidateRow) =>
        candidateRow.id === -1 || (
            candidateRow.driverCode.trim() !== '' &&
            candidates.filter(x => x.driverCode === candidateRow.driverCode).length === 1 &&
            !seriesDrivers.some(d => d.seriesId === seriesId && candidateRow.driverCode === d.driverCode)
        )
)(
    (_, candidateRow) => candidateRow.id
);

const validateDriverCandidatePromoterCodeCell = createCachedSelector(
    [
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeries'),
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesDriver'),
        driverStore.selectors.selectDriverCandidates,
        (_, candidateRow) => candidateRow
    ],
    (seriesId, seriesDrivers, candidates, candidateRow) => {
        var test = candidateRow.id === -1 || candidateRow.promoterCode.trim() === '' || (
            candidateRow.promoterCode.trim() !== '' &&
            candidates.filter(x => x.promoterCode === candidateRow.promoterCode).length === 1 &&
            !seriesDrivers.some(d => d.seriesId === seriesId && d.promoterCodes.some(pc => pc === candidateRow.promoterCode))
        );
        return test;
    }
        
)(
    (_, candidateRow) => candidateRow.id
);

const validateDriverCandidateFirstNameCell = createCachedSelector(
    [
        (_, candidateRow) => candidateRow
    ],
    candidateRow => candidateRow.id === -1 || candidateRow.firstName.trim() !== ''
)(
    (_, candidateRow) => candidateRow.id
);

const validateDriverCandidateLastNameCell = createCachedSelector(
    [
        (_, candidateRow) => candidateRow
    ],
    candidateRow => candidateRow.id === -1 || candidateRow.lastName.trim() !== ''
)(
    (_, candidateRow) => candidateRow.id
);

const validateDriverCandidateRow = createCachedSelector(
    [
        validateDriverCandidateDriverCodeCell,
        validateDriverCandidateFirstNameCell,
        validateDriverCandidateLastNameCell,
        validateDriverCandidatePromoterCodeCell,
        (_, candidateRow) => candidateRow
    ],
    (driverCode, firstName, lastName, promoterCode) => driverCode && firstName && lastName && promoterCode
)(
    (_, candidateRow) => candidateRow.id
);

const validateDriverCandidateAll = createSelector(
    [
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeries'),
        driverStore.selectors.selectDriverCandidates,
        state => driverStore.selectors.selectDriverCandidates(state).every(x => validateDriverCandidateRow(state, x))
    ],
    (seriesId, candidates, rowEval) => seriesId !== '' && candidates.length > 0 && rowEval
);

export const driverCandidates = {
    validateDriverCandidateDriverCodeCell,
    validateDriverCandidateFirstNameCell,
    validateDriverCandidateLastNameCell,
    validateDriverCandidatePromoterCodeCell,
    validateDriverCandidateRow,
    validateDriverCandidateAll
}