import React from 'react';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { EliminationsContestBoxDriver } from './EliminationsContestBoxDriver';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    contestBox: {
        //display: 'flex',
        //margin: '14px',
        //padding: '14px',
        //flexDirection: 'column',
        //backgroundColor: '#252525',

        fontFamily: 'monaco, Consolas, "Lucida Console", monospace',
        fontSize: '14px',
        border: '2px solid #4d4d4d',
        borderRadius: '5px',
        backgroundColor: '#333333',
        padding: '5px 12px',
        marginTop: '10px',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        //alignItems: 'center',
        transform: 'translate(0%, 0%)',
        boxShadow: '10px 10px 15px rgba(0, 0, 0, .55)',
        transition: 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        '-webkitTransition': 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        '-mozTransition': 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        '- oTransition': 'transform .5s ease-in-out, box-shadow .5s ease-in-out, background-color .5s ease-in-out',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',

        webkitUserSelect: 'none',
        userSelect: 'none',

        '&:hover': {
            //backgroundColor: '#ff0000',
            //cursor: 'pointer',

            cursor: 'pointer',
            backgroundColor: '#1b1b1b',
            transform: 'translate(-1%, -1%)',
            boxShadow: '15px 15px 15px rgba(0, 0, 0, .75)',
            transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            '-webkit-transition': 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            '-moz-transition': 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
            '-o-transition': 'transform .1s ease-in-out, box-shadow .1s ease-in-out, background-color .1s ease-in-out',
        },

        '&:active': {
            transform: 'translate(0%, 0%)',
            boxShadow: '10px 10px 15px rgba(0,0,0,.55)',
            transition: 'transform .1s ease-in-out, box-shadow .1s ease-in-out',
            '-webkit-transition': 'transform .1s ease-in-out, box-shadow .1s ease-in-out',
            '-moz-transition': 'transform .1s ease-in-out, box-shadow .1s ease-in-out',
            '-o-transition': 'transform .1s ease-in-out, box-shadow .1s ease-in-out'
        }
    },
    marginBottom: {
        marginBottom: '8px'
    },
    marginTop: {
        marginTop: '8px'
    }
}));

export const EliminationsContestBox = ({ contestId, roundNumber, bracketOrder }) => {

    const classes = useStyles();

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))

    return (
        <Paper className={classes.contestBox} elevation={3}>
            <EliminationsContestBoxDriver className={clsx(classes.marginBottom, classes.marginTop)} contestId={contestId} bracketOrder={1} />
            <EliminationsContestBoxDriver className={classes.marginBottom} contestId={contestId} bracketOrder={2} />
            {syec?.lanes === '4' && <EliminationsContestBoxDriver className={classes.marginBottom} contestId={contestId} bracketOrder={3} />}
            {syec?.lanes === '4' && <EliminationsContestBoxDriver className={classes.marginBottom} contestId={contestId} bracketOrder={4} />}
        </Paper>
    );

};