import { combineReducers } from 'redux';
import { reducer as contextStoreReducer } from './contextStore/contextStoreReducer';
import recordStoreReducer from './recordStore/recordStoreReducer';
import driverStoreReducer from './driverStore/driverStoreReducer';
import userStoreReducer from './userStore/userStoreReducer';
import settingsStoreReducer from './settingsStore/settingsStoreReducer';
import qualifyingSummaryStoreReducer from './qualifyingSummaryStore/qualifyingSummaryStoreReducer';
import queueStoreReducer from './queueStore/queueStoreReducer';
import { reducer as resultDownloadStoreReducer } from './resultDownloadStore/resultDownloadStoreReducer';
import { reducer as signalRStoreReducer } from './signalRStore/signalRStoreReducer';
import { reducer as alertStoreReducer } from './alertStore/alertStoreReducer';
import { reducer as authStoreReducer } from './authStore/authStoreReducer';

export const rootReducer = combineReducers({
    contextStore: contextStoreReducer,
    recordStore: recordStoreReducer,
    driverStore: driverStoreReducer,
    userStore: userStoreReducer,
    settingsStore: settingsStoreReducer,
    qualifyingSummaryStore: qualifyingSummaryStoreReducer,
    queueStore: queueStoreReducer,
    resultDownloadStore: resultDownloadStoreReducer,
    signalRStore: signalRStoreReducer,
    alertStore: alertStoreReducer,
    authStore: authStoreReducer
});