import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import { QualifyingRoundAccordion } from './QualifyingRoundAccordion';

const useStyles = makeStyles((theme) => ({
    tabsContainer: props => ({
        display: 'flex',
        flexDirection: 'column',
        //maxWidth: `${props.lanes * 250}px`,
        userSelect: 'none',
        overflowY: 'hidden',
        flexGrow: '1'
    })
}));

export const QualifyingContestListPanel = () => {

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))
    
    const classes = useStyles(syec);

    const rounds = [];

    if (syec) {
        for (var i = syec.qualifyingRounds; i > 0; i--) {
            rounds.push(
                <QualifyingRoundAccordion key={`qual-round-${i}`} roundNumber={i} />
            );
        }
    }

    return (
        <Box className={classes.tabsContainer}>
            {rounds}
        </Box>
    );

}