import React, { useCallback } from 'react';
import * as authStore from '../../redux/authStore';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import UserLoggedOutIcon from '@material-ui/icons/PersonOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexGrow: '1',
        justifyContent: 'center',
        marginTop: '24px'
    },
    loginContainer: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
        alignItems: 'center'
    },
    loginText: {
        marginBottom: '24px'
    },
    progress: {
        color: '#ffffff'
    },
    authUserPageContainer: {
        display: 'flex',
        flexGrow: '1'
    }
}));

export const AuthUserPagePresenter = ({ children }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const child = React.cloneElement(children, { className: classes.authUserPageContainer });

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const hasCheckedUserInfo = useSelector(state => authStore.selectors.selectHasCheckedUserInfo(state));

    const loginUser = useCallback(() => dispatch(authStore.actionCreators.loginUser()), [dispatch]);

    return isLoggedIn ? child :
        (
            <div className={classes.container}>
                {hasCheckedUserInfo && (
                    <div className={classes.loginContainer}>
                        <h2 className={classes.loginText}>Please log in to access Race Day Tracker</h2>
                        <Button variant='contained' onClick={loginUser} color='primary' size='large' startIcon={<UserLoggedOutIcon />}>Log In</Button>
                    </div>
                )}
                {!hasCheckedUserInfo && <CircularProgress className={classes.progress} />}
            </div>
        );
};