import React, { useState, useCallback, useEffect } from 'react';
import * as recordStore from '../../redux/recordStore';
import * as alertStore from '../../redux/alertStore';
import { useDispatch, useSelector } from 'react-redux';
//import { useSnackbar } from 'notistack';

export const ContestLoader = () => {

    const sye = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEvent'))
    const syeId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEvent'))

    const isLoaded = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesYearEventClassContest'));
    const isLoadError = useSelector(state => recordStore.selectors.selectIsLoadError(state, 'rdtSeriesYearEventClassContest'));
    const loadErrorMessage = useSelector(state => recordStore.selectors.selectLoadErrorMessage(state, 'rdtSeriesYearEventClassContest'));
    const isLoading = useSelector(state => recordStore.selectors.selectIsLoading(state, 'rdtSeriesYearEventClassContest'));
    const needsReload = useSelector(state => recordStore.selectors.selectNeedsReload(state, 'rdtSeriesYearEventClassContest'));

    const dispatch = useDispatch();
    const load = useCallback((filter) => dispatch(recordStore.actionCreators.getRecords('rdtSeriesYearEventClassContest', filter)), [dispatch]);
    const pushAlert = useCallback((message, variant) => dispatch(alertStore.actionCreators.pushAlert(message, variant)), [dispatch]);

    const [prepareAlert, setPrepareAlert] = useState(false);
    const [current, setCurrent] = useState('');

    //const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isLoading && !prepareAlert) {
            setPrepareAlert(true);
        }
        if (!isLoading && prepareAlert) {
            if (isLoadError) {
                pushAlert(`[Contests] API error: ${loadErrorMessage}`, 'error');
                //enqueueSnackbar(`[Contests] API error: ${loadErrorMessage}`, { variant: 'error', persist: true });
            } else {
                pushAlert(`[Contests] API success`, 'success');
                //enqueueSnackbar(`[Contests] API success`, { variant: 'success' });
            }
            setPrepareAlert(false);
        }
    }, [isLoading, prepareAlert, isLoadError, loadErrorMessage, pushAlert]);

    useEffect(() => {
        if (syeId && (needsReload || (!isLoading && !isLoadError && syeId !== current))) {
            pushAlert(`[Contests] Loading contests for ${sye.seriesCode} \\ ${sye.year} \\ ${sye.eventCode}`, 'info');
            //enqueueSnackbar(`[Contests] Loading contests for ${sye.seriesCode} \\ ${sye.year} \\ ${sye.eventCode}`, { variant: 'info' });
            const filter = `?seriesCode=${sye.seriesCode}&year=${sye.year}&eventCode=${sye.eventCode}`;
            setCurrent(syeId);
            load(filter);
        }
    }, [isLoaded, isLoading, isLoadError, load, syeId, setCurrent, current, sye, needsReload, pushAlert]);

    return (
        <div />
    );
}