import * as actionTypes from '../actionTypes';

export const actionCreators = {

    addEventToDownload: syeId => dispatch => {
        dispatch({ type: actionTypes.addEventToDownloadSaga, syeId });
    },

    removeEventToDownload: syeId => dispatch => {
        dispatch({ type: actionTypes.removeEventToDownloadSaga, syeId });
    },

    getEventsDownloading: () => dispatch => {
        dispatch({ type: actionTypes.getEventsDownloadingSaga });
    },

    receiveEventsDownloading: records => dispatch => {
        dispatch({ type: actionTypes.receiveEventsDownloading, records });
    },

}