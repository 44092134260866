import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from './redux/configureStore';
import './App.css';
import { MainPage } from './components/MainPage';
import { Router } from '@reach/router';

const store = configureStore();

export const App = () => (
    <Provider store={store}>
        <Router>
            <MainPage path='/app/*' />
        </Router>
    </Provider>
);