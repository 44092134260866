import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import DataGrid from '@tns/react-data-grid';
import { TextEditor, ValueFormatter } from '@tns/react-data-grid';
import './jkp.css';
import '@tns/react-data-grid/dist/react-data-grid.css';

const useStyles = makeStyles(theme => ({
    datagrid: props => ({
        overflowX: 'hidden',
        overflowY: 'hidden',
        height: `${props.rows * 30 + 2}px !important`,
        width: props.width
    }),
    datagridDirty: {
        backgroundColor: 'red'
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    nameRow: {
        height: '60px'
    },
    dirtyCell: {
        backgroundColor: '#550000',
        marginLeft: '-8px',
        marginRight: '-8px'
    }
}));

const rowKeyGetter = row => row.rowKey;

const handleRowChanging = (newRow, oldRow, onRowChange, columnKey, props) => {
    onRowChange(newRow, false);
};

const handleRowChanged = (newRow, oldRow, onRowChange, columnKey) => {
    //onRowChange(newRow, true);
    var t1 = true;
};

const RdtTextFormatter = props => {
    const classes = useStyles();
    const [className, setClassName] = useState('');
    useEffect(() => {
        setClassName(props.initialTable.find(x => x.rowKey === props.row.rowKey)?.[props.laneNumber] !== props.row[props.laneNumber] ? classes.dirtyCell : 'rdt-nope');
    }, [props.row, props.initialTable]);
    return (
        <div className={className}>
            <ValueFormatter {...props} />
        </div>
    );
};

export const ContestDetail = ({ syec, syecc, setTempRecord, setIsDirty }) => {
    const sd = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesDriver'));

    const styleProps = { rows: 10, width: 500 };

    if (syec) {
        if (syec.distance === '660') {
            styleProps.rows = 8;
        }
        if (syec.distance === '1320') {
            styleProps.rows = 11;
        }

        if (syec.lanes === '4') {
            styleProps.width = 600;
        }
    }

    const classes = useStyles(styleProps);

    const getRows = distance => {
        const rows = [];

        rows.push({ rowKey: 'driverFullName', label: '', l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'guid' });
        rows.push({ rowKey: 'reactionTime', label: 'RT', l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'time' });
        rows.push({ rowKey: 'time60', label: `60'`, l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'time' });
        rows.push({ rowKey: 'time330', label: `330'`, l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'time' });
        rows.push({ rowKey: 'time660', label: `660'`, l1: '', l2: '', l3: '', l4: '', underline: false, rowType: 'time' });
        rows.push({ rowKey: 'speed660', label: '', l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'speed' });

        if (distance === '1000') {
            rows.push({ rowKey: 'time1000', label: `1,000'`, l1: '', l2: '', l3: '', l4: '', underline: false, rowType: 'time' });
            rows.push({ rowKey: 'speed1000', label: '', l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'speed' });
        }

        if (distance === '1320') {
            rows.push({ rowKey: 'time1000', label: `1,000'`, l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'time' });
            rows.push({ rowKey: 'time1320', label: `1,320'`, l1: '', l2: '', l3: '', l4: '', underline: false, rowType: 'time' });
            rows.push({ rowKey: 'speed1320', label: '', l1: '', l2: '', l3: '', l4: '', underline: true, rowType: 'speed' });
        }

        rows.push({ rowKey: 'finishPosition', label: 'Finish', l1: '', l2: '', l3: '', l4: '', underline: false, rowType: 'int' });

        return rows;
    };

    const handleRowsUpdated = rows => {
        setTableData(rows);
        testAndSetIsDirty(rows);
    };

    const [tableData, setTableData] = useState([]);
    const [isTableDirty, setIsTableDirty] = useState(false);

    const isCellDirty = useCallback(
        row => {
            return tableData.find(x => x.rowKey === row.rowKey)?.l1 !== row.l1 ? classes.dirtyCell : '';
        },
        [tableData]
    );

    const getColumns = (lanes, classes) => {
        const laneHeader = lane => {
            return (
                <Box className={classes.headerContainer}>
                    <Typography className={classes.headerCell}>Lane {lane}</Typography>
                </Box>
            );
        };

        if (lanes === '4') {
            const laneWidth = 130;
            const labelWidth = 80;
            return [
                {
                    key: 'l1',
                    name: 'Lane 1',
                    width: laneWidth,
                    editor: p => <TextEditor {...p} onRowChange={newRow => handleRowChanging(newRow, p.row, p.onRowChange, 'l1', p)} />,
                    formatter: p => <RdtTextFormatter {...p} initialTable={initialTable} laneNumber='l1' />,
                    headerRenderer: () => laneHeader(1)
                },
                {
                    key: 'l2',
                    name: 'Lane 2',
                    width: laneWidth,
                    editor: p => <TextEditor {...p} onRowChange={newRow => handleRowChanging(newRow, p.row, p.onRowChange, 'l2', p)} />,
                    formatter: p => <RdtTextFormatter {...p} initialTable={initialTable} laneNumber='l2' />,
                    headerRenderer: () => laneHeader(2)
                },
                {
                    key: 'label',
                    width: labelWidth,
                    name: ''
                },
                {
                    key: 'l3',
                    name: 'Lane 3',
                    width: laneWidth,
                    editor: p => <TextEditor {...p} onRowChange={newRow => handleRowChanging(newRow, p.row, p.onRowChange, 'l3', p)} />,
                    formatter: p => <RdtTextFormatter {...p} initialTable={initialTable} laneNumber='l3' />,
                    headerRenderer: () => laneHeader(3)
                },
                {
                    key: 'l4',
                    name: 'Lane 4',
                    width: laneWidth,
                    editor: p => <TextEditor {...p} onRowChange={newRow => handleRowChanging(newRow, p.row, p.onRowChange, 'l4', p)} />,
                    formatter: p => <RdtTextFormatter {...p} initialTable={initialTable} laneNumber='l4' />,
                    headerRenderer: () => laneHeader(4)
                }
            ];
        } else {
            const laneWidth = '40%';
            const labelWidth = '20%';
            return [
                {
                    key: 'l1',
                    name: 'Lane 1',
                    width: laneWidth,
                    editor: p => <TextEditor {...p} onRowChange={newRow => handleRowChanging(newRow, p.row, p.onRowChange, 'l1', p)} />,
                    formatter: p => <RdtTextFormatter {...p} initialTable={initialTable} laneNumber='l1' />,
                    headerRenderer: () => laneHeader(1)
                },
                {
                    key: 'label',
                    width: labelWidth,
                    name: ''
                },
                {
                    key: 'l2',
                    name: 'Lane 2',
                    width: laneWidth,
                    editor: p => <TextEditor {...p} onRowChange={newRow => handleRowChanging(newRow, p.row, p.onRowChange, 'l2', p)} />,
                    formatter: p => <RdtTextFormatter {...p} initialTable={initialTable} laneNumber='l2' />,
                    headerRenderer: () => laneHeader(2)
                }
            ];
        }
    };

    const getInitialTable = useCallback(() => {
        if (syec) {
            const emptyTable = getRows(syec.distance);
            if (syecc) {
                let lane1 = syecc.runs.find(x => x.lane === '1');
                let lane2 = syecc.runs.find(x => x.lane === '2');
                let lane3 = syecc.runs.find(x => x.lane === '3');
                let lane4 = syecc.runs.find(x => x.lane === '4');
                if (lane1) {
                    let d = sd.find(x => x.id === lane1.seriesDriverId);
                    if (d) {
                        lane1 = { ...lane1, driverFirstName: d.firstName, driverLastName: d.lastName, driverFullName: `${d.firstName} ${d.lastName}`.trim() };
                    }
                }
                if (lane2) {
                    let d = sd.find(x => x.id === lane2.seriesDriverId);
                    if (d) {
                        lane2 = { ...lane2, driverFirstName: d.firstName, driverLastName: d.lastName, driverFullName: `${d.firstName} ${d.lastName}`.trim() };
                    }
                }
                if (lane3) {
                    let d = sd.find(x => x.id === lane3.seriesDriverId);
                    if (d) {
                        lane3 = { ...lane3, driverFirstName: d.firstName, driverLastName: d.lastName, driverFullName: `${d.firstName} ${d.lastName}`.trim() };
                    }
                }
                if (lane4) {
                    let d = sd.find(x => x.id === lane4.seriesDriverId);
                    if (d) {
                        lane4 = { ...lane4, driverFirstName: d.firstName, driverLastName: d.lastName, driverFullName: `${d.firstName} ${d.lastName}`.trim() };
                    }
                }
                if (lane1 || lane2 || lane3 || lane4) {
                    const tempData = emptyTable.map(row => {
                        if (lane1) {
                            row.l1 = lane1[row.rowKey];
                        }
                        if (lane2) {
                            row.l2 = lane2[row.rowKey];
                        } else {
                            row.l2 = '';
                        }
                        if (lane3) {
                            row.l3 = lane3[row.rowKey];
                        }
                        if (lane4) {
                            row.l4 = lane4[row.rowKey];
                        }
                        return row;
                    });
                    return tempData;
                }
            } else {
                return emptyTable;
            }
        }
    }, [syec, syecc, sd]);

    const initialTable = useMemo(() => getInitialTable(), [getInitialTable]);

    const testAndSetIsDirty = useCallback(
        newTable => {
            let isDirty = initialTable.some(x => {
                if (x.rowType === 'guid') {
                    return (
                        x.l1 !== newTable.find(y => y.rowKey === x.rowKey).l1 ||
                        x.l2 !== newTable.find(y => y.rowKey === x.rowKey).l2 ||
                        x.l3 !== newTable.find(y => y.rowKey === x.rowKey).l3 ||
                        x.l4 !== newTable.find(y => y.rowKey === x.rowKey).l4
                    );
                }
                if (x.rowType === 'time' || x.rowType === 'speed' || x.rowType === 'int') {
                    return (
                        Number(x.l1) !== Number(newTable.find(y => y.rowKey === x.rowKey).l1) ||
                        Number(x.l2) !== Number(newTable.find(y => y.rowKey === x.rowKey).l2) ||
                        Number(x.l3) !== Number(newTable.find(y => y.rowKey === x.rowKey).l3) ||
                        Number(x.l4) !== Number(newTable.find(y => y.rowKey === x.rowKey).l4)
                    );
                }
                return true;
            });
            setIsTableDirty(isDirty);
        },
        [initialTable]
    );

    useEffect(() => {
        setIsDirty(isTableDirty);
    }, [isTableDirty]);

    useEffect(() => {
        setTempRecord(tableData);
    }, [tableData]);

    useEffect(() => {
        setTableData(initialTable);
        setIsTableDirty(false);
    }, [initialTable]);

    const columns = useMemo(() => getColumns(syec?.lanes, classes), [syec, classes, initialTable]);

    return (
        <DataGrid
            rowKeyGetter={rowKeyGetter}
            columns={columns}
            className={clsx({ [classes.datagridDirty]: isTableDirty }, classes.datagrid)}
            enableCellSelect={true}
            onRowsChange={handleRowsUpdated}
            rowHeight={30}
            //rowRenderer={RdtRowRenderer}
            rowClass={row => clsx('rdt-grid-row', row.underline ? 'rdt-underline-row' : 'rdt-no-underline-row')}
            rows={tableData}
        />
    );
};
