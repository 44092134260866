import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as recordStore from '../../redux/recordStore';
import * as contextStore from '../../redux/contextStore';
import * as queueStore from '../../redux/queueStore';
import { useSelector, useDispatch } from 'react-redux';
import { QualifyingContestRow } from './QualifyingContestRow';
import CloudDownloadOutlineIcon from 'mdi-material-ui/CloudDownloadOutline';
import ProgressClockIcon from 'mdi-material-ui/ProgressClock';
import clsx from 'clsx';
import AlarmCheckIcon from 'mdi-material-ui/AlarmCheck';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    contestList: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'auto'
    },
    detailsPanel: {
        backgroundColor: '#222',
        padding: 0,
        overflowY: 'auto'
    },
    expanderHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginRight: '12px',
        alignItems: 'center'
    },
    roundLive: {
        color: '#b7dfb9',
        backgroundColor: '#071107',
        border: '2px solid #4caf50',
        padding: '8px'
    },
    roundLastComplete: {
        '&.Mui-disabled': {
            color: '#4caf50',
        },
        color: '#4caf50',
    },
    accordion: {
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        //minHeight: '64px',
        '& .MuiCollapse-entered': {
            overflowY: 'auto'
        }
    }
}));

const StyledAccordionSummary = withStyles({
    content: {
        margin: '4px',
        '&.Mui-expanded': {
            margin: '4px'
        }
    },
    root: {
        '&.Mui-expanded': {
            minHeight: '48px'
        }
    }
})(AccordionSummary);

export const QualifyingRoundAccordion = props => {

    const classes = useStyles();

    const { roundNumber } = props;

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))
    const syecc = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClassContest'))
    const contests = syecc
        .filter(x => x.classCode === syec.classCode && x.roundType === 'q' && x.roundNumber === roundNumber.toString())
        .sort((a, b) => (a.roundOrder < b.roundOrder) ? 1 : -1);

    const dispatch = useDispatch();
    const queueImportRound = useCallback(
        () => dispatch(queueStore.actionCreators.queueImportEventClassRound(syec.seriesCode, syec.year, syec.eventCode, syec.classCode, 'q', roundNumber)),
        [dispatch, syec, roundNumber]
    );

    const handleQueueImportRound = e => {
        e.stopPropagation();
        queueImportRound();
    };

    const stopPropagation = e => {
        e.stopPropagation();
    }

    const isNextRound = useSelector(state => contextStore.selectors.selectIsNextQualifyingRound(state, roundNumber))
    const isLiveRound = useSelector(state => contextStore.selectors.selectIsLiveQualifyingRound(state, roundNumber))
    const isLastCompleteRound = useSelector(state => contextStore.selectors.selectIsLastCompleteQualifyingRound(state, roundNumber))

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (syec && (isLiveRound || (isLastCompleteRound && !syec.isQualifyingLive))) {
            setIsExpanded(true);
        } else {
            setIsExpanded(false);
        }
    }, [syec, isLiveRound, isNextRound, isLastCompleteRound]);

    const saveUpdatedRecord = useCallback(newRecord => dispatch(recordStore.actionCreators.updateRecord('rdtSeriesYearEventClass', newRecord)), [dispatch]);
    const setNeedsReload = useCallback(() => dispatch(recordStore.actionCreators.setNeedsReload('rdtSeriesYearEventClass')), [dispatch]);

    const toggleIsLive = e => {
        stopPropagation(e);
        const newRecord = { ...syec, isQualifyingLive: !syec.isQualifyingLive };
        saveUpdatedRecord(newRecord);
        setNeedsReload();
    };

    const setLastCompleteRound = e => {
        stopPropagation(e);
        const newRecord = { ...syec };
        let isDirty = false;
        if (isLastCompleteRound && syec.qualifyingResultsThroughRound !== '0') {
            newRecord.qualifyingResultsThroughRound = '0';
            isDirty = true;
        }
        if (!isLastCompleteRound && syec.qualifyingResultsThroughRound !== roundNumber.toString()) {
            newRecord.isQualifyingLive = false;
            newRecord.qualifyingResultsThroughRound = roundNumber.toString();
            isDirty = true;
        }
        if (isDirty) {
            saveUpdatedRecord(newRecord);
            setNeedsReload();
        }
    };

    return (
        <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)} className={classes.accordion}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={classes.expanderHeader}>
                    <Typography>Q{roundNumber}</Typography>
                    <Box className={classes.icons}>
                        {isNextRound &&
                            <IconButton onClick={toggleIsLive} onFocus={stopPropagation} className={clsx({
                                [classes.roundLive]: isLiveRound
                            })}>
                                <ProgressClockIcon />
                            </IconButton>
                        }
                        <IconButton onClick={setLastCompleteRound} onFocus={stopPropagation} className={clsx({
                            [classes.roundLastComplete]: isLastCompleteRound
                        })}>
                            <AlarmCheckIcon />
                        </IconButton>
                        {syec && syec.seriesCode.startsWith('nhra') &&
                            <IconButton onClick={handleQueueImportRound} onFocus={stopPropagation}>
                                <CloudDownloadOutlineIcon />
                            </IconButton>
                        }
                    </Box>
                </Box>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.detailsPanel}>
                <Box className={classes.contestList}>
                    {contests.map(x => <QualifyingContestRow key={x.id} contest={x} lanes={syec.lanes} />)}
                </Box>
            </AccordionDetails>
        </Accordion>
    );

}