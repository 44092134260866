import React from 'react';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';

export const QualifyingSummaryTableDriverRow = props => {

    const { row } = props;

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))
    const syecc = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClassContest'))

    const rounds = [];
    const showDialIn = syec.timingType !== 'quickestTime';

    var liveRoundNumber = 0;
    if (syec.isQualifyingLive) {
        liveRoundNumber = Number(syec.qualifyingResultsThroughRound) + 1;
    }

    var selected = '';
    if (syecc && syecc.runs.find(x => x.driverCode === row.driverCode)) {
        selected = 'jkp-selected';
    } else {
        selected = '';
    }

    for (var i2 = 1; i2 <= Number(syec.qualifyingRounds); i2++) {

        const i = i2;

        var liveRoundClass = '';
        if (liveRoundNumber === i) {
            liveRoundClass = ' jkp-live-qualifying-round';
        }

        var dqClass = liveRoundClass;
        if (row[`q${i.toString()}-dq`]) {
            dqClass = `disqualified${liveRoundClass}`;
        }

        rounds.push([
            <td className={dqClass} key={'q' + i + '-display-time'}>{row['q' + i + '-display-time']}</td>,
            <td className={dqClass} key={'q' + i + '-display-speed'}>{row['q' + i + '-display-speed']}</td>
        ]);
        if (showDialIn) {
            rounds.push([
                //<td key={'q' + i + '-display-dialIn'}>{row['q' + i + '-display-dialIn']}</td>,
                <td className={dqClass} key={'q' + i + '-display-overUnderDialIn'}>{row['q' + i + '-display-overUnderDialIn']}</td>
            ]);
        }
        rounds.push([
            <td className={`dashedborderleft${liveRoundClass}`} key={'q' + i + '-display-best-time'}>{row['q' + i + '-display-best-time']}</td>
        ]);
        if (showDialIn) {
            rounds.push([
                <td className={liveRoundClass.trim()} key={'q' + i + '-display-best-speed'}>{row['q' + i + '-display-best-speed']}</td>,
                //<td key={'q' + i + '-display-best-dialIn'}>{row['q' + i + '-display-best-dialIn']}</td>,
                <td className={`solidborderright${liveRoundClass}`} key={'q' + i + '-display-best-overUnderDialIn'}>{row['q' + i + '-display-best-overUnderDialIn']}</td>
            ]);
        } else {
            rounds.push([
                <td className={`solidborderright${liveRoundClass}`} key={'q' + i + '-display-best-speed'}>{row['q' + i + '-display-best-speed']}</td>
            ]);
        }
    }

    return (
        <tr key={row.driverCode} className={selected}>
            <td className={'numbercolumn'}>{row.sortOrder}</td>
            <td className={'numbercolumn'}>{row.qualifyingPosition}</td>
            <td className={'namecolumn solidborderright'}>{row.driverName}</td>
            {rounds}
        </tr>
    );
}