import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ContestDetail } from './ContestDetail';
import { ContestDetailToolbar } from './ContestDetailToolbar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import * as recordStore from '../../redux/recordStore';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
    container: {
        marginTop: '10px',
        //width: '500px'
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column'
    },
    accordion: {
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        //minHeight: '64px',
        '& .MuiCollapse-entered': {
            overflowY: 'auto'
        },
        flexShrink: '0'
    },
    detailsPanel: {
        backgroundColor: '#222',
        //padding: 0,
        //overflowY: 'auto',
        display: 'flex'
    },
    expanderHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginRight: '12px',
        alignItems: 'center'
    }
}));

const StyledAccordionSummary = withStyles({
    content: {
        margin: '4px',
        '&.Mui-expanded': {
            margin: '4px'
        }
    },
    root: {
        '&.Mui-expanded': {
            minHeight: '48px'
        }
    }
})(AccordionSummary);

export const ContestDetailPanel = () => {
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(true);

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'));
    const syecc = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClassContest'));
    const dispatch = useDispatch();
    const updateContest = record => dispatch(recordStore.actionCreators.updateRecord('rdtSeriesYearEventClassContest', record));
    const getContests = () => dispatch(recordStore.actionCreators.getRecords('rdtSeriesYearEventClassContest'));

    const [isDirty, setIsDirty] = useState(false);
    const [tempRecord, setTempRecord] = useState({});
    const saveUpdatedRecord = () => {
        let t = tempRecord;
        let t2 = syecc;

        let newRecord = _.cloneDeep(syecc);
        let isDirty = false;
        newRecord.runs.forEach(oldRun => tempRecord.forEach(newRun => {
            if (oldRun[newRun.rowKey] !== newRun[`l${oldRun.lane}`]) {
                oldRun[newRun.rowKey] = newRun[`l${oldRun.lane}`];
                isDirty = true;
            }
        }))
        updateContest(newRecord);
        //getContests();
        let x = true;
    };

    return (
        <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)} className={classes.accordion}>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box className={classes.expanderHeader}>
                    <Typography>Contest Details</Typography>
                    <Box className={classes.icons}>
                        <ContestDetailToolbar isDirty={isDirty} onSave={saveUpdatedRecord} />
                    </Box>
                </Box>
            </StyledAccordionSummary>
            <AccordionDetails className={classes.detailsPanel}>
                <Box className={classes.container}>{syec && <ContestDetail syec={syec} syecc={syecc} setTempRecord={setTempRecord} setIsDirty={setIsDirty} />}</Box>
            </AccordionDetails>
        </Accordion>
    );
};
