import * as actionTypes from '../actionTypes';

const initialState = {
    isSaveAlertOpen: false,
    sortProperty: '',
    sortRound: '',
    isSortAscending: true
}

export default (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.setIsQualifyingSummarySaveAlertOpen:
            return {
                ...state,
                isSaveAlertOpen: action.isSaveAlertOpen
            }

        case actionTypes.setQualifyingSummaryTableSort:
            return {
                ...state,
                sortProperty: action.sortProperty,
                sortRound: action.sortRound,
                isSortAscending: action.isSortAscending,
            }

        default:
            return state;
    }
};