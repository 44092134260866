import * as actionTypes from '../actionTypes';
import { recordTypes } from './recordStoreRecordTypes';

const initialState = () => {
    var output = recordTypes.reduce((acc, cur) => {
        return {
            ...acc,
            [cur.recordTypeName]: {
                records: [],
                isLoaded: false,
                isLoading: false,
                isLoadError: false,
                needsReload: false,
                loadErrorMessage: '',
                newRecordId: '',
                selectedId: ''
            }
        }
    }, {});
    return output;
}

export default (state, action) => {

    state = state || initialState();

    switch (action.type) {

        case actionTypes.setNeedsReload:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    needsReload: true
                }
            }

        case actionTypes.setIsLoading:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    isLoading: true,
                    isLoaded: false,
                    needsReload: false
                }
            }

        case actionTypes.setIsLoadError:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    isLoadError: action.isLoadError,
                    loadErrorMessage: action.loadErrorMessage,
                    isLoading: false
                }
            }

        case actionTypes.receiveRecords:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: action.records,
                    isLoaded: true,
                    isLoading: false
                }
            }

        case actionTypes.deleteRecordSuccess:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: [...state[action.recordType].records.filter(x => x.id !== action.recordId)],
                    isLoaded: true,
                    isLoading: false
                }
            }

        case actionTypes.receiveSingleRecord:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: [...state[action.recordType].records.filter(x => x.id !== action.record.id), action.record],
                    isLoaded: true,
                    isLoading: false
                }
            }

        case actionTypes.createRecordSuccess:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    records: [...state[action.recordType].records.filter(x => x.id !== action.record.id), action.record],
                    isLoaded: true,
                    isLoading: false
                }
            }

        case actionTypes.pushNewRecordId:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    newRecordId: action.newRecordId
                }
            }

        case actionTypes.popNewRecordId:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    newRecordId: ''
                }
            }

        case actionTypes.setSelectedId:
            return {
                ...state,
                [action.recordType]: {
                    ...state[action.recordType],
                    selectedId: action.selectedId
                }
            }

        default:
            return state;
    }
};