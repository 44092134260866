import * as actionTypes from '../actionTypes';

const initialState = {
    eventsDownloading: []
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.receiveEventsDownloading:
            return {
                ...state,
                eventsDownloading: action.records
            }

        default:
            return state;
    }
};