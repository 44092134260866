//import * as actionTypes from '../actionTypes';

const initialState = {

}

export default (state, action) => {

    state = state || initialState;

    switch (action.type) {

        default:
            return state;
    }
};