import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import { QualifyingContestListPanel } from './QualifyingContestListPanel';
import { ContestDetailPanel } from './ContestDetailPanel';
import { QualifyingSummaryTable } from './QualifyingSummaryTable';
import { QualifyingSummaryTableToolbar } from './QualifyingSummaryTableToolbar';
import { PageContentPresenter } from '../container/PageContentPresenter';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexGrow: '1',
        overflow: 'hidden'
    },
    containerLeftSide: {
        display: 'flex',
        flexGrow: '0',
        flexDirection: 'column'
    },
    containerRightSide: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column'
    },
    roundContests: {
        flexGrow: '1'
    },
    contestDetail: {
        flexGrow: '0'
    }
}));

export const QualifyingPage = () => {

    const classes = useStyles();

    const syecId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass'))

    return (
        <PageContentPresenter showBreadcrumbs={true} selectionRequiredCondition={syecId} selectionRequiredText={'Please select an event class'}>
            <Box className={classes.container}>
                <Box className={classes.containerLeftSide}>
                    <QualifyingContestListPanel className={classes.roundContests} />
                    <ContestDetailPanel className={classes.contestDetail} />
                </Box>
                <Box className={classes.containerRightSide}>
                    <QualifyingSummaryTableToolbar />
                    <QualifyingSummaryTable />
                </Box>
            </Box>
        </PageContentPresenter>
    );

}