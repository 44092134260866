import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import * as recordStore from '../../redux/recordStore';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    contestRow: {
        display: 'flex',
        //justifyContent: 'space-around',
        padding: '6px 12px',
        '&:hover': {
            cursor: 'pointer'
        },
        '&:not(:first-child)': {
            borderTop: 'gray solid 1px'
        }
    },
    contestRowHover: {
        '&:hover': {
            backgroundColor: '#0ff',
            color: '#000'
        }
    },
    oneRowName: {
        flex: '1 1 0px',
        textAlign: 'center'
    },
    selectedRow: {
        backgroundColor: '#630101'
    },
    twoRowName: {
        flex: '1 0 0',
        alignItems: 'center',
        flexDirection: 'column',
        display: 'flex'
    }
}));

export const QualifyingContestRow = props => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { lanes, contest } = props;

    const sd = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesDriver'))

    const selectedSeriesYearEventClassContestId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClassContest'))
    const saveSelectedSeriesYearEventClassContestId = (id) => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYearEventClassContest', id));

    const selectRow = (e, contestId) => {
        if (selectedSeriesYearEventClassContestId === contestId) {
            saveSelectedSeriesYearEventClassContestId('');
        } else {
            saveSelectedSeriesYearEventClassContestId(contestId);
        }
    };

    const getDriverNames = () => {
        let names = [];
        for (let i = 1; i <= Number(lanes); i++) {
            let run = contest.runs.find(x => x.lane === i.toString());
            let d = null;
            if (run) {
                d = sd.find(x => x.id === run.seriesDriverId);
            }
            if (lanes === '2') {
                names.push(<Typography key={`contest-${contest.id}-lane-${i}`} className={classes.oneRowName}>{d ? `${d.firstName} ${d.lastName}`.trim() : '-'}</Typography>);
            }
            if (lanes === '4') {
                names.push(
                    <Box key={`contest-${contest.id}-lane-${i}`} className={classes.twoRowName}>
                        <Typography className={classes.lane}>{(d && d.firstName) ? d.firstName.trim() : '-'}</Typography>
                        <Typography className={classes.lane}>{(d && d.lastName) ? d.lastName.trim() : '-'}</Typography>
                    </Box>
                );
            }
        }
        return names;
    };

    return (
        <Box className={clsx(classes.contestRow, { [classes.contestRowHover]: selectedSeriesYearEventClassContestId !== contest.id, [classes.selectedRow]: selectedSeriesYearEventClassContestId === contest.id })} onClick={(e) => selectRow(e, contest.id)}>
            {getDriverNames()}
        </Box>
    );

}