import * as actionTypes from '../actionTypes';

export const actionCreators = {

    queueProcessEvent: (seriesCode, year, eventCode) => dispatch => {
        dispatch({ type: actionTypes.queueProcessEventSaga, seriesCode, year, eventCode });
    },

    queueImportEventClassRound: (seriesCode, year, eventCode, classCode, roundType, roundNumber) => dispatch => {
        dispatch({ type: actionTypes.queueImportEventClassRoundSaga, seriesCode, year, eventCode, classCode, roundType, roundNumber });
    },

    queueSetQualifyingOrder: (seriesCode, year, eventCode, classCode, roundType, roundNumber) => dispatch => {
        dispatch({ type: actionTypes.queueSetQualifyingOrderSaga, seriesCode, year, eventCode, classCode, roundType, roundNumber });
    }

}