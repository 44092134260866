import { put, takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as resultDownloadStore from './';

function* watchGetEventsDownloading() {
    yield takeEvery(actionTypes.getEventsDownloadingSaga, getEventsDownloadingAsync);
}

function* getEventsDownloadingAsync() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const url = `/api/nhra-download-settings/events`;
    try {
        var res = yield call(fetch, url);
        if (!res.ok) {
            // TODO: Add queueing to handle results that can be reported via toast popups
            //yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, `Get records API returned status code ${res.status} ${res.statusText}`));
        } else {
            var data = yield res.json();
            yield put(resultDownloadStore.actionCreators.receiveEventsDownloading(data));
        }
    } catch (err) {
        //yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

function* watchAddEventToDownload() {
    yield takeEvery(actionTypes.addEventToDownloadSaga, addEventToDownloadAsync);
}

function* addEventToDownloadAsync(action) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const url = `/api/nhra-download-settings/events/${action.syeId.toLowerCase()}`;
    const data = { youBig: 'dummy' };
    const options = { method: 'POST', headers: headers, body: JSON.stringify(data) };
    yield sendRequest(url, options);
    yield put(resultDownloadStore.actionCreators.getEventsDownloading());
}

function* watchRemoveEventToDownload() {
    yield takeEvery(actionTypes.removeEventToDownloadSaga, removeEventToDownloadAsync);
}

function* removeEventToDownloadAsync(action) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('If-Match', 'dummy');
    const url = `/api/nhra-download-settings/events/${action.syeId.toLowerCase()}`;
    const options = { method: 'DELETE', headers: headers };
    yield sendRequest(url, options);
    yield put(resultDownloadStore.actionCreators.getEventsDownloading());
}

function* sendRequest(url, options) {
    try {
        var res = yield call(fetch, url, options);
        if (!res.ok) {
            // TODO: Add queueing to handle results that can be reported via toast popups
            //yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, `Get records API returned status code ${res.status} ${res.statusText}`));
        } else {
            //var data = yield res.json();
            //yield put(recordStore.actionCreators.receiveRecords(action.recordType, data));
        }
    } catch (err) {
        //yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

export const sagaWatchers = sagaMiddleware => {
    sagaMiddleware.run(watchGetEventsDownloading);
    sagaMiddleware.run(watchAddEventToDownload);
    sagaMiddleware.run(watchRemoveEventToDownload);
}