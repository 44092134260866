import React from 'react';
import clsx from 'clsx';
import { UserList } from './UserList';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import * as userStore from '../../redux/userStore';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
    gridButtonRow: {
        gridRow: 1,
        minHeight: '48px',
        display: 'flex',
        justifyContent: 'flex-end',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '5px 5px 0 0'
    },
    gridListRow: {
        gridRow: 3,
        overflowY: 'auto'
    },
    dividerRow: {
        gridRow: 2,
    },
    tableHeaderLabel: {
        flexGrow: 1,
    },
    paper: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr'
    },
    rotate: {
        animation: 'rotation 2s infinite linear'
    }
}));

export const UserListPanel = () => {

    const classes = useStyles();
    
    const usersIsLoading = useSelector(state => userStore.selectors.selectUsersIsLoading(state));

    const dispatch = useDispatch();
    const refreshUsers = () => dispatch(userStore.actionCreators.getUsers());

    return (
        <Paper elevation={5} className={classes.paper}>
            <Toolbar className={classes.gridButtonRow}>
                <Typography className={classes.tableHeaderLabel}>USERS</Typography>
                <IconButton color='inherit' aria-label='refresh users' onClick={refreshUsers} disabled={usersIsLoading} className={clsx({ [classes.rotate]: usersIsLoading })}>
                    <RefreshIcon fontSize='small' />
                </IconButton>
            </Toolbar>
            <Divider className={classes.dividerRow} />
            <Box className={classes.gridListRow}>
                <UserList />
            </Box>
        </Paper>
    );
};