import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { SelectedEventBreadcrumbs } from '../breadcrumbs/SelectedEventBreadcrumbs';

const useStyles = makeStyles(theme => ({
    selectContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: '1'
    },
    noSelect: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    breadcrumbContainer: {
        backgroundColor: '#1f1f1f',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    selectionRequiredContainer: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column',
    },
    selectionRequiredPageContainer: {
        flexGrow: '1',
        margin: theme.spacing(3),
        overflowY: 'hidden',
        display: 'flex'
    }
}));

export const PageContentPresenter = ({ showBreadcrumbs, selectionRequiredCondition, selectionRequiredText, children }) => {

    const classes = useStyles();

    return (
        <Box className={classes.selectionRequiredContainer}>
            {showBreadcrumbs &&
                <Box className={classes.breadcrumbContainer}>
                    <SelectedEventBreadcrumbs />
                </Box>
            }
            <Box className={classes.selectionRequiredPageContainer}>
                {(showBreadcrumbs && !selectionRequiredCondition) ?
                    <Box className={classes.selectContainer}>
                        <h2 className={classes.noSelect}>{selectionRequiredText}</h2>
                    </Box>
                    :
                    children
                }
            </Box>
        </Box>
    );
};