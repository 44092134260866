import React, { useCallback, useEffect } from 'react';
import * as signalRStore from '../../redux/signalRStore';
import * as recordStore from '../../redux/recordStore';
import * as alertStore from '../../redux/alertStore';
import * as authStore from '../../redux/authStore';
import { useDispatch, useSelector } from 'react-redux';
import LanConnectIcon from 'mdi-material-ui/LanConnect';
import LanDisconnectIcon from 'mdi-material-ui/LanDisconnect';
import * as signalR from '@microsoft/signalr';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        padding: '5px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '10px'
    },
    connectedContainer: {
        color: 'rgb(183, 223, 185)',
        backgroundColor: 'rgb(7, 17, 7)',
        borderColor: '#4caf50',
    },
    disconnectedContainer: {
        color: 'rgb(250, 179, 174)',
        backgroundColor: 'rgb(24, 6, 5)',
        borderColor: '#f44336',
    }
}));

export const SignalRManager = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));

    const setConnectionStatus = useCallback(isConnected => dispatch(signalRStore.actionCreators.setSignalRConnectionStatus(isConnected)), [dispatch]);
    const setNeedsReload = useCallback(recordType => dispatch(recordStore.actionCreators.setNeedsReload(recordType)), [dispatch]);
    const isConnected = useSelector(state => signalRStore.selectors.selectIsConnected(state));
    const pushAlert = useCallback((message, variant) => dispatch(alertStore.actionCreators.pushAlert(message, variant)), [dispatch]);

    useEffect(() => {
        if (isLoggedIn) {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl('https://api.racedaytracker.com/rdthub')
                .configureLogging(signalR.LogLevel.Information)
                .build();

            async function start() {
                try {
                    await connection.start();
                    pushAlert('SignalR connected', 'info');
                    setConnectionStatus(true);
                } catch (err) {
                    console.log(err);
                    setConnectionStatus(false);
                    //setTimeout(start, 5000);
                }
            };

            connection.onclose(start);

            connection.on('ClassQualifyingUpdated', (roundUpdated) => {
                if (roundUpdated !== null) {
                    pushAlert('Message received: ' + roundUpdated.seriesCode + ' - ' + roundUpdated.year + ' - ' + roundUpdated.eventCode + ' - ' + roundUpdated.classCode + ' - ' + roundUpdated.roundType + roundUpdated.roundNumber, 'info');
                    setNeedsReload('rdtSeriesYearEventClassContest');
                    setNeedsReload('rdtSeriesYearEventClassDriver');
                }
            });

            // Start the connection.
            start();
        }
    }, [isLoggedIn, pushAlert, setConnectionStatus, setNeedsReload]);

    return (
        <Box className={clsx(classes.container, isConnected ? classes.connectedContainer : classes.disconnectedContainer)}>
            {isConnected ? <LanConnectIcon /> : <LanDisconnectIcon />}
        </Box>
    );
}