import React, { useState, useCallback, useEffect } from 'react';
import * as recordStore from '../redux/recordStore';
import * as authStore from '../redux/authStore';
import * as alertStore from '../redux/alertStore';
import { useDispatch, useSelector } from 'react-redux';

export const RecordLoadAlert = props => {

    const { recordType, filter } = props;

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));

    const isLoaded = useSelector(state => recordStore.selectors.selectIsLoaded(state, recordType));
    const isLoadError = useSelector(state => recordStore.selectors.selectIsLoadError(state, recordType));
    const loadErrorMessage = useSelector(state => recordStore.selectors.selectLoadErrorMessage(state, recordType));
    const isLoading = useSelector(state => recordStore.selectors.selectIsLoading(state, recordType));
    const needsReload = useSelector(state => recordStore.selectors.selectNeedsReload(state, recordType));

    const dispatch = useDispatch();
    const load = useCallback(() => dispatch(recordStore.actionCreators.getRecords(recordType, filter)), [dispatch, recordType, filter]);
    const pushAlert = useCallback((message, variant) => dispatch(alertStore.actionCreators.pushAlert(message, variant)), [dispatch]);

    const [prepareAlert, setPrepareAlert] = useState(false);

    useEffect(() => {
        if (isLoggedIn) {
            if (isLoading) {
                setPrepareAlert(true);
            }
            if (!isLoading && prepareAlert) {
                if (isLoadError) {
                    pushAlert(`[${recordType}] API error: ${loadErrorMessage}`, 'error');
                } else {
                    pushAlert(`[${recordType}] API success`, 'success');
                }
                setPrepareAlert(false);
            }
        }
    }, [isLoggedIn, isLoading, prepareAlert, isLoadError, loadErrorMessage, recordType, pushAlert]);

    useEffect(() => {
        if (isLoggedIn) {
            if (needsReload || (!isLoaded && !isLoading && !isLoadError)) {
                load();
            }
        }
    }, [isLoggedIn, isLoaded, isLoading, isLoadError, load, needsReload]);

    return (
        <div />
    );
}