import { put, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import axios from 'axios';
import * as authStore from './';
import * as alertStore from '../alertStore';

function* watchLoginUser() {
    yield takeEvery(actionTypes.loginUserSaga, loginUser);
}

function loginUser() {
    const path = window.location.pathname;
    const query = window.location.search ?? '';
    const redirectUrl = encodeURIComponent(`${path}${query}`);
    window.location.href = `/user/login?redirect_uri=${redirectUrl}`;
}

function* watchLogoutUser() {
    yield takeEvery(actionTypes.logoutUserSaga, logoutUser);
}

function logoutUser() {
    const path = window.location.pathname;
    const query = window.location.search ?? '';
    const redirectUrl = encodeURIComponent(`${path}${query}`);
    window.location.href = `/user/logout?redirect_uri=${redirectUrl}`;
}

function* watchGetAuthUserInfo() {
    yield takeEvery(actionTypes.getAuthUserInfoSaga, getAuthUserInfoAsync);
}

function* getAuthUserInfoAsync() {
    const url = '/user/info';
    const result = yield axios.get(url).catch(error => error);
    if (result.isAxiosError) {
        yield put(alertStore.actionCreators.pushAlert(`Auth user info call failed with ${result.response.status}`, 'error'));
    } else {
        yield put(authStore.actionCreators.receiveAuthUserInfo(result.data.isLoggedIn, result.data.rdtUserId, result.data.firstName, result.data.lastName, result.data.name, result.data.email, result.data.accessToken, result.data.idToken, result.data.claims));
        yield put(alertStore.actionCreators.pushAlert(`Auth user info call succeeded with isLoggedIn=${result.data.isLoggedIn} and rdtUserId=${result.data.rdtUserId}`, 'success'));
    }
}

export const sagaWatchers = sagaMiddleware => {
    sagaMiddleware.run(watchLoginUser);
    sagaMiddleware.run(watchLogoutUser);
    sagaMiddleware.run(watchGetAuthUserInfo);
}