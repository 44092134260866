import * as actionTypes from '../actionTypes';

export const actionCreators = {

    setIsSaveAlertOpen: (isSaveAlertOpen) => dispatch => {
        dispatch({ type: actionTypes.setIsQualifyingSummarySaveAlertOpen, isSaveAlertOpen });
    },

    setTableSort: (sortProperty, sortRound, isSortAscending) => dispatch => {
        dispatch({ type: actionTypes.setQualifyingSummaryTableSort, sortProperty, sortRound, isSortAscending });
    }

}