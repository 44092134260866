import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

const selectUsers = state => state.userStore.users;
const selectUsersIsLoaded = state => state.userStore.usersIsLoaded;
const selectUsersIsLoading = state => state.userStore.usersIsLoading;
const selectIsLoadErrorUsers = state => state.userStore.isLoadErrorUsers;
const selectLoadErrorMessageUsers = state => state.userStore.loadErrorMessageUsers;
const selectSelectedUserId = state => state.userStore.selectedUserId;

const selectUserById = createCachedSelector(
    [
        selectUsers,
        (_, id) => id
    ],
    (records, id) => records.find(x => x.id === id)
)(
    (_, id) => id
);

const selectSelectedUser = createSelector(
    [
        selectUsers,
        selectSelectedUserId
    ],
    (records, id) => records.find(x => x.id === id)
);

const selectUserByIdExists = createCachedSelector(
    [
        selectUsers,
        (_, id) => id
    ],
    (records, id) => records.some(x => x.id === id)
)(
    (_, id) => id
);

export const selectors = {
    selectUsers,
    selectUsersIsLoaded,
    selectUsersIsLoading,
    selectUserById,
    selectUserByIdExists,
    selectIsLoadErrorUsers,
    selectLoadErrorMessageUsers,
    selectSelectedUserId,
    selectSelectedUser
}