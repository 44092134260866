import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import ErrorIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(() => ({
    errorCell: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
    },
    errorCellText: {
        flexGrow: '1'
    },
    errorCellIcon: {
        color: '#ff0000'
    }
}));

export const DriversTableValidationCell = (props) => {
    const classes = useStyles();
    const isValid = useSelector(state => props.validator(state, props.row));

    if (isValid) {
        return <>{props.row[props.propertyName]}</>;
    } else {
        return <Box className={classes.errorCell}><Box className={classes.errorCellText}>{props.row[props.propertyName]}</Box><ErrorIcon className={classes.errorCellIcon} /></Box>;
    }
}