import { put, takeEvery, select, all, call } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as driverStore from './';
import * as recordStore from '../recordStore';
import axios from 'axios';

function* watchSaveDriverCandidates() {
    yield takeEvery(actionTypes.saveDriverCandidatesSaga, saveDriverCandidatesAsync);
}

function* saveDriverCandidatesAsync() {
    const url = '/api/series-drivers';
    const series = yield select(recordStore.selectors.selectSelectedRecord, 'rdtSeries');
    const candidates = yield select(driverStore.selectors.selectDriverCandidates);

    if (series) {

        var allResults = yield all(
            candidates.map((candidate) => {
                const newDriver = {
                    seriesId: series.id,
                    seriesCode: series.seriesCode,
                    driverCode: candidate.driverCode,
                    promoterCodes: candidate.promoterCode ? [candidate.promoterCode] : [],
                    promoterNames: candidate.fullName ? [candidate.fullName] : [],
                    lastName: candidate.lastName,
                    firstName: candidate.firstName,
                    dateOfBirth: '',
                    primaryDriverPhotoKey: '',
                    primaryDriverPhotoUrl: '',
                    primaryCarPhotoRightKey: '',
                    primaryCarPhotoRightUrl: '',
                    primaryCarPhotoLeftKey: '',
                    primaryCarPhotoLeftUrl: '',
                    partitionKey: 'rdtseriesdriver',
                    rdtType: 'rdtSeriesDriver',
                    isDeleted: false,
                    isHidden: false,
                    id: '',
                    eTag: ''
                }
                return axios.post(url, newDriver).catch(error => error);
            })
        )

        if (allResults.some(x => x.isAxiosError)) {
            alert(`${allResults.filter(x => x.isAxiosError).length} API calls failed! ${allResults.filter(x => !x.isAxiosError).length} API calls succeeded!`);
        } else {
            alert(`${allResults.length} successful calls!`);
        }

        //yield put(recordStore.actionCreators.getRecords('rdtSeriesDriver'));
        yield put(recordStore.actionCreators.setNeedsReload('rdtSeriesDriver'));
    }
}

function* watchGetUnknownDrivers() {
    yield takeEvery(actionTypes.getUnknownDriversSaga, getUnknownDriversAsync);
}

function* getUnknownDriversAsync(action) {
    yield put(driverStore.actionCreators.setIsLoadingUnknownDrivers(true));
    const url = `/api/series/${action.seriesCode}/years/${action.year}/events/${action.eventCode}/results/event-classes/${action.classCode}/event-class-drivers/unknown`;
    //const url = `/api-old/unknown-drivers/${action.eventId}/${action.eventClassId}`;
    const res = yield call(axios.get, url);
    yield put(driverStore.actionCreators.receiveUnknownDrivers(res.data));
    yield put(driverStore.actionCreators.setIsLoadingUnknownDrivers(false));
    yield put(driverStore.actionCreators.processUnknownDrivers());
}

function* watchProcessUnknownDrivers() {
    yield takeEvery(actionTypes.processUnknownDriversSaga, processUnknownDriversAsync);
}

function* processUnknownDriversAsync() {
    const unknowns = yield select(driverStore.selectors.selectUnknownDrivers);
    const candidates = unknowns.map((x, idx) => {
        const names = x.fullName.split(' ');
        const firstName = names.length > 0 ? names[0] : '';
        const lastName = names.length > 1 ? names.slice(1).join(' ') : '';
        const driverCode = `${lastName.replace('.', '').replace(',', '').replace(' ', '-').toLowerCase()}-${firstName.replace('.', '').replace(',', '').replace(' ', '-').toLowerCase()}`
        return {
            ...x,
            id: idx,
            unknownDriverId: x.id,
            firstName,
            lastName,
            driverCode
        };
    });
    candidates.sort((a, b) => a.driverCode > b.driverCode ? 1 : a.driverCode < b.driverCode ? -1 : 0);
    yield put(driverStore.actionCreators.updateDriverCandidates(candidates));
}

export const driverStoreSagaWatchers = (sagaMiddleware) => {
    sagaMiddleware.run(watchSaveDriverCandidates);
    sagaMiddleware.run(watchGetUnknownDrivers);
    sagaMiddleware.run(watchProcessUnknownDrivers);
}