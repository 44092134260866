import { put, takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as userStore from './';

function* watchGetUsers() {
    yield takeEvery(actionTypes.getUsersSaga, getUsersAsync);
}

function* getUsersAsync() {
    yield put(userStore.actionCreators.setIsLoadingUsers(true));
    var url = '/api/identity/users';
    try {
        var res = yield call(fetch, url);

        if (!res.ok) {
            yield put(userStore.actionCreators.setIsLoadErrorUsers(true, `API returned status code ${res.status} ${res.statusText}`));
            yield put(userStore.actionCreators.setIsLoadingUsers(false));
        } else {
            var data = yield res.json();
            yield put(userStore.actionCreators.receiveUsers(data));
            yield put(userStore.actionCreators.setIsLoadingUsers(false));
        }
    } catch (err) {
        yield put(userStore.actionCreators.setIsLoadErrorUsers(true, err.message));
        yield put(userStore.actionCreators.setIsLoadingUsers(false));
    }
}

export const userStoreSagaWatchers = (sagaMiddleware) => {
    sagaMiddleware.run(watchGetUsers);
}