import * as actionTypes from '../actionTypes';

const initialState = {
    users: [],
    usersIsLoaded: false,
    usersIsLoading: false,
    isLoadErrorUsers: false,
    loadErrorMessageUsers: '',
    selectedUserId: ''
};

export default (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.setIsLoadingUsers:
            return {
                ...state,
                usersIsLoading: action.isLoadingUsers
            }

        case actionTypes.setIsLoadErrorUsers:
            return {
                ...state,
                isLoadErrorUsers: action.isLoadErrorUsers,
                loadErrorMessageUsers: action.loadErrorMessageUsers
            }

        case actionTypes.receiveUsers:
            return {
                ...state,
                users: action.records,
                usersIsLoaded: true
            }

        case actionTypes.setSelectedUserId:
            return {
                ...state,
                selectedUserId: action.selectedUserId
            }

        default:
            return state;
    }
};