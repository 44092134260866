import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { UserPage } from './users/UserPage';
import { DriversPage } from './drivers/DriversPage';
import { QualifyingOrderPage } from './qualifyingOrder/QualifyingOrderPage';
import { QualifyingPage } from './qualifying/QualifyingPage';
import { UserInfoPage } from './auth/UserInfoPage';
import { EliminationsPage } from './eliminations/EliminationsPage';
import { SignalRManager } from './signalR/SignalRManager';
import { RdtSnackbarProvider } from './snackbar/RdtSnackbarProvider';
import { RdtAppBar } from './container/RdtAppBar';
import { AuthUserPagePresenter } from './auth/AuthUserPagePresenter';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import NumberListIcon from '@material-ui/icons/FormatListNumbered';
import ReorderListIcon from '@material-ui/icons/LowPriority';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Link, Router, useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import * as settingsStore from '../redux/settingsStore';
import * as userStore from '../redux/userStore';
import 'typeface-roboto';
import { RecordLoadAlert } from './RecordLoadAlert';
import HelmetIcon from '@material-ui/icons/SportsMotorsports';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import TournamentIcon from 'mdi-material-ui/Tournament';
import { ContestLoader } from './breadcrumbs/ContestLoader';
import { UrlQueryStringManager } from './container/UrlQueryStringManager';
import { SchedulePage } from './schedule/SchedulePage';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        marginTop: '64px',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '1200px'
    },
    router: {
        flexGrow: 1,
        overflowY: 'hidden',
        overflowX: 'auto',
        display: 'flex'
    },
    bottomIconContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginBottom: '30px'
    },
    bottomIconListItem: {
        paddingLeft: '12px'
    },
    noSelect: {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none'
    },
    divider: {
        margin: '8px 0'
    }
}));

export const MainPage = () => {
    const classes = useStyles();
    const location = useLocation();

    const useDarkTheme = useSelector(state => settingsStore.selectors.selectUseDarkTheme(state));
    const selectedUserId = useSelector(state => userStore.selectors.selectSelectedUserId(state));

    const [open, setOpen] = useState(false);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const theme = useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: useDarkTheme ? 'dark' : 'light',
                    primary: {
                        main: useDarkTheme ? '#252525' : '#3f51b5',
                        dark: useDarkTheme ? '#050505' : '#303f9f',
                        light: useDarkTheme ? '#b5b5b5' : '#7986cb',
                        contrastText: useDarkTheme ? '#fff' : '#fff'
                    },
                    secondary: {
                        main: useDarkTheme ? '#f50057' : '#00ffff',
                        dark: useDarkTheme ? '#c51162' : '#c51162',
                        light: useDarkTheme ? '#ff4081' : '#ff4081',
                        contrastText: useDarkTheme ? '#fff' : '#424242'
                    }
                },
                typography: {
                    fontFamily: 'Consolas'
                }
            }),
        [useDarkTheme]
    );

    //const Blank = () => (
    //    <div>Empty page...</div>
    //);

    const getUserLinkTo = () => {
        return selectedUserId && selectedUserId !== '' ? `/app/users/${selectedUserId}` : '/app/users';
    };

    return (
        <ThemeProvider theme={theme}>
            <RdtSnackbarProvider>
                <div className={classes.root}>
                    <CssBaseline />
                    <RecordLoadAlert recordType='rdtSeries' />
                    <RecordLoadAlert recordType='rdtSeriesClass' />
                    <RecordLoadAlert recordType='rdtSeriesDriver' />
                    <RecordLoadAlert recordType='rdtSeriesYear' />
                    <RecordLoadAlert recordType='rdtSeriesYearEvent' />
                    <RecordLoadAlert recordType='rdtSeriesYearEventClass' />
                    <RecordLoadAlert recordType='rdtSeriesYearEventClassDriver' />

                    <UrlQueryStringManager />
                    <ContestLoader />

                    <RdtAppBar open={open} setOpen={setOpen} />
                    <Drawer variant='permanent' className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open })} classes={{ paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open }) }}>
                        <div className={classes.toolbar}>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>

                        <Divider />

                        <List>
                            <ListItem button component={Link} to='/app/schedule' selected={location.pathname === '/app/schedule'}>
                                <ListItemIcon>
                                    <CalendarIcon />
                                </ListItemIcon>
                                <ListItemText primary='Manage Schedule' />
                            </ListItem>
                            <ListItem button component={Link} to='/app/add-drivers' selected={location.pathname === '/app/add-drivers'}>
                                <ListItemIcon>
                                    <HelmetIcon />
                                </ListItemIcon>
                                <ListItemText primary='Add Drivers' />
                            </ListItem>
                            <ListItem button component={Link} to='/app/qualifying' selected={location.pathname === '/app/qualifying'}>
                                <ListItemIcon>
                                    <NumberListIcon />
                                </ListItemIcon>
                                <ListItemText primary='Qualifying' />
                            </ListItem>
                            <ListItem button component={Link} to='/app/qualifying-order' selected={location.pathname === '/app/qualifying-order'}>
                                <ListItemIcon>
                                    <ReorderListIcon />
                                </ListItemIcon>
                                <ListItemText primary='Qualifying Order' />
                            </ListItem>
                            <ListItem button component={Link} to='/app/eliminations' selected={location.pathname === '/app/eliminations'}>
                                <ListItemIcon>
                                    <TournamentIcon />
                                </ListItemIcon>
                                <ListItemText primary='Eliminations' />
                            </ListItem>
                            <Divider className={classes.divider} />
                            <ListItem button component={Link} to={getUserLinkTo()} selected={location.pathname.startsWith('/app/users')}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary='Users' />
                            </ListItem>
                        </List>

                        <Box className={classes.bottomIconContainer}>
                            <ListItem className={classes.bottomIconListItem}>
                                <ListItemIcon>
                                    <SignalRManager />
                                </ListItemIcon>
                                <ListItemText className={classes.noSelect} primary='Live Updates' />
                            </ListItem>
                        </Box>
                    </Drawer>

                    <main className={classes.content}>
                        <Router className={classes.router}>
                            <AuthUserPagePresenter path='/users/*userId'>
                                <UserPage path='/' />
                            </AuthUserPagePresenter>
                            <AuthUserPagePresenter path='/add-drivers'>
                                <DriversPage path='/' />
                            </AuthUserPagePresenter>
                            <AuthUserPagePresenter path='/qualifying'>
                                <QualifyingPage path='/' />
                            </AuthUserPagePresenter>
                            <AuthUserPagePresenter path='/qualifying-order'>
                                <QualifyingOrderPage path='/' />
                            </AuthUserPagePresenter>
                            <AuthUserPagePresenter path='/eliminations'>
                                <EliminationsPage path='/' />
                            </AuthUserPagePresenter>
                            <AuthUserPagePresenter path='/schedule'>
                                <SchedulePage path='/' />
                            </AuthUserPagePresenter>
                            <UserInfoPage path='/account' />
                        </Router>
                    </main>
                </div>
            </RdtSnackbarProvider>
        </ThemeProvider>
    );
};
