import React from 'react';
import * as authStore from '../../redux/authStore';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { PageContentPresenter } from '../container/PageContentPresenter';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1'
    },
    button: {
        marginTop: '24px',
        width: '150px'
    },
    header: {
        marginTop: '24px',
        marginBottom: '6px'
    },
    claimRow: {
        display: 'flex'
    },
    claimType: {
        width: '100px',
        marginRight: '12px'
    }
}));

export const UserInfoPage = () => {

    const classes = useStyles();

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const accessToken = useSelector(state => authStore.selectors.selectAccessToken(state));
    const idToken = useSelector(state => authStore.selectors.selectIdToken(state));
    const claims = useSelector(state => authStore.selectors.selectClaims(state));

    return (
        <PageContentPresenter showBreadcrumbs={false}>
            <div className={classes.container}>
                <div className={classes.header}>Is logged in?</div>
                <div className={classes.row}>{isLoggedIn.toString()}</div>
                <Button className={classes.button} disabled={isLoggedIn} variant='contained' color='primary' href='/user/login?provider_code=twitter&external_username=goldfingersixfo&redirect_uri=/app/account'>Login</Button>
                <Button className={classes.button} disabled={!accessToken} variant='contained' color='primary' target='_blank' rel='noopener' href={`https://jwt.ms#id_token=${accessToken}`}>Access Token</Button>
                <Button className={classes.button} disabled={!idToken} variant='contained' color='primary' target='_blank' rel='noopener' href={`https://jwt.ms#id_token=${idToken}`}>ID Token</Button>
                <div className={classes.header}>Claims:</div>
                <div className={classes.row}>
                    {
                        claims?.map((x, idx) => (
                            <div className={classes.claimRow} key={`claim-${idx}`}>
                                <div className={classes.claimType}>{x.claimType}</div>
                                <div className={classes.claimValue}>{x.claimValue}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </PageContentPresenter>
    );
};