import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloudPrintOutlineIcon from 'mdi-material-ui/CloudPrintOutline';
import * as recordStore from '../../redux/recordStore';
import * as queueStore from '../../redux/queueStore';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    toolbar: {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingLeft: 0,
        paddingRight: 0
    }
}));

export const QualifyingSummaryTableToolbar = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))

    const queueSortRuns = useCallback(
        () => dispatch(queueStore.actionCreators.queueSetQualifyingOrder(syec.seriesCode, syec.year, syec.eventCode, syec.classCode, 'q', syec.qualifyingResultsThroughRound)),
        [dispatch, syec]
    );

    return (
        <Toolbar className={classes.toolbar}>
            <IconButton onClick={queueSortRuns}>
                <CloudPrintOutlineIcon />
            </IconButton>
        </Toolbar>
    );

}