import createCachedSelector from 're-reselect';
//import { createSelector } from 'reselect';
import * as recordStore from '../recordStore';

const selectIsEventSelectionChanging = state => state.contextStore.isEventSelectionChanging;
const selectIsUrlQueryChanging = state => state.contextStore.isUrlQueryChanging;

const selectDriversForSelectedSeries = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesDriver'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeries')
    ],
    (records, seriesId) => records.filter(x => x.seriesId === seriesId)
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeries')
);

const selectYearsForSelectedSeries = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYear'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeries')
    ],
    (records, seriesId) => records.filter(x => x.seriesId === seriesId)
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeries')
);

const selectEventsForSelectedSeriesYear = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEvent'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYear')
    ],
    (records, seriesYearId) => records.filter(x => x.seriesYearId === seriesYearId)
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYear')
);

const selectClassesForSelectedSeriesYearEvent = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClass'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEvent')
    ],
    (records, seriesYearEventId) => records.filter(x => x.seriesYearEventId === seriesYearEventId)
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEvent')
);

const selectClassDriversForSelectedSeriesYearEventClass = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClassDriver'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
    ],
    (records, seriesYearEventClassId) => records.filter(x => x.seriesYearEventClassId === seriesYearEventClassId)
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectContestsForSelectedSeriesYearEventClass = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClassContest'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
    ],
    (records, seriesYearEventClassId) => records.filter(x => x.seriesYearEventClassId === seriesYearEventClassId)
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectQualifyingContestsForSelectedSeriesYearEventClass = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClassContest'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
    ],
    (records, seriesYearEventClassId) => records.filter(x => x.seriesYearEventClassId === seriesYearEventClassId && x.roundType === 'q')
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectEliminationContestsForSelectedSeriesYearEventClass = createCachedSelector(
    [
        state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClassContest'),
        state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
    ],
    (records, seriesYearEventClassId) => records.filter(x => x.seriesYearEventClassId === seriesYearEventClassId && x.roundType === 'e')
)(
    state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectIsNextQualifyingRound = createCachedSelector(
    [
        state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'),
        (_, roundNumber) => roundNumber
    ],
    (syec, roundNumber) => syec.qualifyingResultsThroughRound === (Number(roundNumber) - 1).toString()
)(
    (state, _) => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectIsLiveQualifyingRound = createCachedSelector(
    [
        selectIsNextQualifyingRound,
        state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'),
        (_, roundNumber) => roundNumber
    ],
    (isNextRound, syec, roundNumber) => isNextRound && syec.isQualifyingLive
)(
    (state, _) => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectIsLastCompleteQualifyingRound = createCachedSelector(
    [
        state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'),
        (_, roundNumber) => roundNumber
    ],
    (syec, roundNumber) => syec.qualifyingResultsThroughRound === roundNumber.toString()
)(
    (state, _) => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

const selectRunByBracketOrder = createCachedSelector(
    [
        selectEliminationContestsForSelectedSeriesYearEventClass,
        (_state, contestId) => contestId,
        (_state, _contestId, bracketOrder) => bracketOrder.toString()
    ],
    (contests, contestId, bracketOrder) => {
        let c = contests.find(x => x.id === contestId);
        if (c) {
            let r = c.runs.find(x => x.bracketOrder === bracketOrder);
            return r;
        }
        return null;
    }
)(
    (state, _contestId, _bracketOrder) => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')
);

export const selectors = {
    selectIsEventSelectionChanging,
    selectIsUrlQueryChanging,
    selectDriversForSelectedSeries,
    selectYearsForSelectedSeries,
    selectEventsForSelectedSeriesYear,
    selectClassesForSelectedSeriesYearEvent,
    selectClassDriversForSelectedSeriesYearEventClass,
    selectContestsForSelectedSeriesYearEventClass,
    selectQualifyingContestsForSelectedSeriesYearEventClass,
    selectEliminationContestsForSelectedSeriesYearEventClass,
    selectIsNextQualifyingRound,
    selectIsLiveQualifyingRound,
    selectIsLastCompleteQualifyingRound,
    selectRunByBracketOrder
}