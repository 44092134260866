import React, { useEffect, useCallback } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Box from '@material-ui/core/Box';
import * as qualifyingSummaryStore from '../../redux/qualifyingSummaryStore';
import * as recordStore from '../../redux/recordStore';
import { useDispatch, useSelector } from 'react-redux';
import SortNumericDescending from 'mdi-material-ui/SortNumericDescending';
import SortNumericAscending from 'mdi-material-ui/SortNumericAscending';
import './QualifyingSummaryTableHeader.css';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    sortableHeaderCell: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    sortableHeaderCellWithIcon: {
        justifyContent: 'space-between'
    },
    sortableHeaderCellNoIcon: {
        justifyContent: 'flex-end'
    }
}));

export const QualifyingSummaryTableHeader = () => {

    const dispatch = useDispatch();
    const classes = useStyles();

    const ec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))
    const sortProperty = useSelector(state => qualifyingSummaryStore.selectors.selectSortProperty(state));
    const sortRound = useSelector(state => qualifyingSummaryStore.selectors.selectSortRound(state));
    const isSortAscending = useSelector(state => qualifyingSummaryStore.selectors.selectIsSortAscending(state));

    const setTableSort = useCallback((newSortProperty, newSortRound, newIsSortAscending) => dispatch(qualifyingSummaryStore.actionCreators.setTableSort(newSortProperty, newSortRound, newIsSortAscending)), [dispatch]);

    const sortColumn = (newSortRound, newSortProperty) => {
        newSortRound = newSortRound.toString();
        var newIsSortAscending = true;
        if (sortRound === newSortRound && sortProperty === newSortProperty) {
            newIsSortAscending = !isSortAscending;
        }
        setTableSort(newSortProperty, newSortRound, newIsSortAscending);
    }

    const getSortIcon = (colSortRound, colSortProperty, label) => {
        colSortRound = colSortRound.toString();
        var icon;
        var className;
        if (sortRound === colSortRound && sortProperty === colSortProperty) {
            if (isSortAscending) {
                icon = <SortNumericAscending />;
            } else {
                icon = <SortNumericDescending />;
            }
            className = clsx(classes.sortableHeaderCell, classes.sortableHeaderCellWithIcon);
        } else {
            className = clsx(classes.sortableHeaderCell, classes.sortableHeaderCellNoIcon);
        }
        return (
            <Box className={className}>
                {icon}
                <Box>
                    {label}
                </Box>
            </Box>
        );
    }

    useEffect(() => {
        if (ec) {
            var round = ec.qualifyingRounds;
            var sort = 'best-time';
            if (ec.timingType !== 'quickestTime') {
                sort = 'best-overUnderDialIn';
            }
            setTableSort(sort, round, true);
        }
    }, [ec, setTableSort]);

    if (ec == null) return null;
    const showDialIn = ec.timingType !== 'quickestTime';
    var liveRoundNumber = 0;
    if (ec.isQualifyingLive) {
        liveRoundNumber = Number(ec.qualifyingResultsThroughRound) + 1;
    }
    let headers = [
        <TableCell className={'numbercolumn nobottomborder'} key={'sort-header'} />,
        <TableCell className={'numbercolumn nobottomborder'} key={'qual-header'} />,
        <TableCell className={'solidborderright namecolumn nobottomborder'} key={'name-header'} />
    ];
    const subheaders = [
        <TableCell className={'numbercolumn nobottomborder notopborder'} key={'sort-subheader'} />,
        <TableCell className={'numbercolumn nobottomborder notopborder'} key={'qual-subheader'} />,
        <TableCell className={'solidborderright namecolumn nobottomborder notopborder'} key={'name-subheader'} />
    ];
    const subsubheaders = [
        <TableCell className={'numbercolumn notopborder'} key={'sort-subsubheader'} />,
        <TableCell className={'numbercolumn notopborder'} key={'qual-subsubheader'} />,
        <TableCell className={'solidborderright namecolumn notopborder'} key={'name-subsubheader'} />
    ];
    for (var i2 = 1; i2 <= Number(ec.qualifyingRounds); i2++) {
        const i = i2;
        var liveRoundClass = '';
        if (liveRoundNumber === i) {
            liveRoundClass = ' jkp-live-qualifying-round';
        }
        headers.push(...[
            <TableCell className={`solidborderright${liveRoundClass}`} colSpan={showDialIn ? 6 : 4} key={'q' + i + '-header'}>{'Q' + i}</TableCell>
        ]);
        subheaders.push(...[
            <TableCell className={`dashedborderright${liveRoundClass}`} colSpan={showDialIn ? 3 : 2} key={'q' + i + '-round-header'}>{'Round'}</TableCell>,
            <TableCell className={`solidborderright${liveRoundClass}`} colSpan={showDialIn ? 3 : 2} key={'q' + i + '-best-header'}>{'Best'}</TableCell>
        ]);
        subsubheaders.push(...[
            <TableCell className={`datacolumn${liveRoundClass}`} key={'q' + i + '-display-time-header'} onClick={() => sortColumn(i, 'time')}>{getSortIcon(i, 'time', 'ET')}</TableCell>,
            <TableCell className={`datacolumn${liveRoundClass}`} key={'q' + i + '-display-speed-header'} onClick={() => sortColumn(i, 'speed')}>{getSortIcon(i, 'speed', 'Speed')}</TableCell>
        ]);
        if (showDialIn) {
            subsubheaders.push(...[
                //<TableCell className={'datacolumn'} key={'q' + i + '-display-dialIn-header'} onClick={this.sortColumn.bind(this, i, 'dialIn')}>{this.getSortIcon(i, 'dialIn', 'DI')}</TableCell>,
                <TableCell className={`datacolumn${liveRoundClass}`} key={'q' + i + '-display-overUnderDialIn-header'} onClick={() => sortColumn(i, 'overUnderDialIn')}>{getSortIcon(i, 'overUnderDialIn', '+/-')}</TableCell>
            ]);
        }
        subsubheaders.push(...[
            <TableCell className={`datacolumn dashedborderleft${liveRoundClass}`} key={'q' + i + '-display-best-time-header'} onClick={() => sortColumn(i, 'best-time')}>{getSortIcon(i, 'best-time', 'ET')}</TableCell>
        ]);
        if (showDialIn) {
            subsubheaders.push(...[
                <TableCell className={`datacolumn${liveRoundClass}`} key={'q' + i + '-display-best-speed-header'} onClick={() => sortColumn(i, 'best-speed')}>{getSortIcon(i, 'best-speed', 'Speed')}</TableCell>,
                //<TableCell className={'datacolumn'} key={'q' + i + '-display-best-dialIn-header'} onClick={this.sortColumn.bind(this, i, 'best-dialIn')}>{this.getSortIcon(i, 'best-dialIn', 'DI')}</TableCell>,
                <TableCell className={`datacolumn solidborderright${liveRoundClass}`} key={'q' + i + '-display-best-overUnderDialIn-header'} onClick={() => sortColumn(i, 'best-overUnderDialIn')}>{getSortIcon(i, 'best-overUnderDialIn', '+/-')}</TableCell>
            ]);
        } else {
            subsubheaders.push(...[
                <TableCell className={`datacolumn solidborderright${liveRoundClass}`} key={'q' + i + '-display-best-speed-header'} onClick={() => sortColumn(i, 'best-speed')}>{getSortIcon(i, 'best-speed', 'Speed')}</TableCell>
            ]);
        }
    }

    return (
        <TableHead>
            <TableRow>
                {headers}
            </TableRow>
            <TableRow>
                {subheaders}
            </TableRow>
            <TableRow className={'subsubheader'}>
                {subsubheaders}
            </TableRow>
        </TableHead>
    );
}