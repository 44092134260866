import * as actionTypes from '../actionTypes';

export const actionCreators = {

    loginUser: () => dispatch => {
        dispatch({ type: actionTypes.loginUserSaga });
    },

    logoutUser: () => dispatch => {
        dispatch({ type: actionTypes.logoutUserSaga });
    },

    getAuthUserInfo: () => dispatch => {
        dispatch({ type: actionTypes.getAuthUserInfoSaga });
    },

    receiveAuthUserInfo: (isLoggedIn, rdtUserId, userFirstName, userLastName, userFullName, userEmail, accessToken, idToken, claims) => dispatch => {
        dispatch({ type: actionTypes.receiveAuthUserInfo, isLoggedIn, rdtUserId, userFirstName, userLastName, userFullName, userEmail, accessToken, idToken, claims });
    }

}