// recordStore
export const getRecordsSaga = 'GET_RECORDS_SAGA';
export const createRecordSaga = 'CREATE_RECORD_SAGA';
export const createRecordSuccess = 'CREATE_RECORD_SUCCESS';
export const pushNewRecordId = 'PUSH_NEW_RECORD_ID';
export const popNewRecordId = 'POP_NEW_RECORD_ID';
export const updateRecordsSaga = 'UPDATE_RECORDS_SAGA';
export const deleteRecordSaga = 'DELETE_RECORD_SAGA';
export const receiveRecords = 'RECEIVE_RECORDS';
export const deleteRecord = 'DELETE_RECORD';
export const deleteRecordSuccess = 'DELETE_RECORD_SUCCESS';
export const receiveSingleRecord = 'RECEIVE_SINGLE_RECORD';
export const setIsLoading = 'SET_IS_LOADING';
export const setSelectedId = 'SET_SELECTED_ID';
export const setIsLoadError = 'SET_IS_LOAD_ERROR';
export const setNeedsReload = 'SET_NEEDS_RELOAD';

// userStore
export const getUsersSaga = 'GET_USERS_SAGA';
export const receiveUsers = 'RECEIVE_USERS';
export const setIsLoadingUsers = 'SET_IS_LOADING_USERS';
export const setSelectedUserId = 'SET_SELECTED_USER_ID';
export const setIsLoadErrorUsers = 'SET_IS_LOAD_ERROR_USERS';

// driverStore
export const getUnknownDriversSaga = 'GET_UNKNOWN_DRIVERS_SAGA';
export const receiveUnknownDrivers = 'RECEIVE_UNKNOWN_DRIVERS';
export const setIsLoadingUnknownDrivers = 'SET_IS_LOADING_UNKNOWN_DRIVERS';
export const setIsLoadErrorUnknownDrivers = 'SET_IS_LOAD_ERROR_UNKNOWN_DRIVERS';

export const processUnknownDriversSaga = 'PROCESS_UNKNOWN_DRIVERS_SAGA';
export const updateDriverCandidates = 'UPDATE_DRIVER_CANDIDATES';
export const saveDriverCandidatesSaga = 'SAVE_DRIVER_CANDIDATES_SAGA';

// settingsStore
export const setUseDarkTheme = 'SET_USE_DARK_THEME';

// qualifyingSummaryStore
export const setIsQualifyingSummarySaveAlertOpen = 'SET_IS_QUALIFYING_SUMMARY_SAVE_ALERT_OPEN';
export const setQualifyingSummaryTableSort = 'SET_QUALIFYING_SUMMARY_TABLE_SORT';

// queueStore
export const queueProcessEventSaga = 'QUEUE_PROCESS_EVENT_SAGA';
export const queueImportEventClassRoundSaga = 'QUEUE_IMPORT_EVENT_CLASS_ROUND_SAGA';
export const queueSetQualifyingOrderSaga = 'QUEUE_SET_QUALIFYING_ORDER_SAGA';

// resultDownloadStore
export const addEventToDownloadSaga = 'ADD_EVENT_TO_DOWNLOAD_SAGA';
export const removeEventToDownloadSaga = 'REMOVE_EVENT_TO_DOWNLOAD_SAGA';
export const getEventsDownloadingSaga = 'GET_EVENTS_DOWNLOADING_SAGA';
export const receiveEventsDownloading = 'RECEIVE_EVENTS_DOWNLOADING';

// signalRStore
export const setSignalRConnectionStatus = 'SET_SIGNALR_CONNECTION_STATUS';

// alertStore
export const pushAlert = 'PUSH_ALERT';
export const removeAlert = 'REMOVE_ALERT';

// authStore
export const loginUserSaga = 'LOGIN_USER_SAGA';
export const logoutUserSaga = 'LOGOUT_USER_SAGA';
export const getAuthUserInfoSaga = 'GET_AUTH_USER_INFO_SAGA';
export const receiveAuthUserInfo = 'RECEIVE_AUTH_USER_INFO';

// contextStore
export const setIsEventSelectionChanging = 'SET_IS_EVENT_SELECTION_CHANGING';
export const setIsUrlQueryChanging = 'SET_IS_URL_QUERY_CHANGING';