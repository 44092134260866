import { userStoreSagaWatchers } from './userStore/userStoreSagaWatchers';
import { driverStoreSagaWatchers } from './driverStore/driverStoreSagaWatchers';
import { recordStoreSagaWatchers } from './recordStore/recordStoreSagaWatchers';
import { queueStoreSagaWatchers } from './queueStore/queueStoreSagaWatchers';
import { sagaWatchers as resultDownloadStoreSagaWatchers } from './resultDownloadStore/resultDownloadStoreSagaWatchers';
import { sagaWatchers as signalRStoreSagaWatchers } from './signalRStore/signalRStoreSagaWatchers';
import { sagaWatchers as authStoreSagaWatchers } from './authStore/authStoreSagaWatchers';

export const runSagas = sagaMiddleware => {
    userStoreSagaWatchers(sagaMiddleware);
    driverStoreSagaWatchers(sagaMiddleware);
    recordStoreSagaWatchers(sagaMiddleware);
    queueStoreSagaWatchers(sagaMiddleware);
    resultDownloadStoreSagaWatchers(sagaMiddleware);
    signalRStoreSagaWatchers(sagaMiddleware);
    authStoreSagaWatchers(sagaMiddleware);
}