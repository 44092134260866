import { put, takeEvery, call, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import * as recordStore from './';
import * as alertStore from '../alertStore';
import { recordTypes } from './recordStoreRecordTypes';
import axios from 'axios';

const getUrl = (recordType) => {
    return `/api/${recordTypes.find(x => x.recordTypeName === recordType).urlEndpoint ?? recordType}`;
}

function* watchGetRecords() {
    yield takeEvery(actionTypes.getRecordsSaga, getRecordsAsync);
}

function* getRecordsAsync(action) {
    yield put(recordStore.actionCreators.setIsLoading(action.recordType));
    var url = getUrl(action.recordType);
    if (action.filter) {
        url = `${url}${action.filter}`;
    }
    try {
        var res = yield call(fetch, url);

        if (!res.ok) {
            yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, `Get records API returned status code ${res.status} ${res.statusText}`));
        } else {
            var data = yield res.json();
            yield put(recordStore.actionCreators.receiveRecords(action.recordType, data));
        }
    } catch (err) {
        yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

function* watchCreateRecord() {
    yield takeEvery(actionTypes.createRecordSaga, createRecordAsync);
}

function* createRecordAsync(action) {
    yield put(recordStore.actionCreators.setIsLoading(action.recordType));
    var url = getUrl(action.recordType);
    try {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const res = yield call(fetch, url, { method: 'POST', headers, body: JSON.stringify(action.record) });
        if (!res.ok) {
            yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, `Create record API returned status code ${res.status} ${res.statusText}`));
        } else {
            var data = yield res.json();
            yield put(recordStore.actionCreators.createRecordSuccess(action.recordType, data));
            yield put(recordStore.actionCreators.pushNewRecordId(action.recordType, data.id));
        }
    } catch (err) {
        yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

function* watchUpdateRecords() {
    yield takeEvery(actionTypes.updateRecordsSaga, updateRecordsAsync);
}

function* updateRecordsAsync(action) {
    yield put(recordStore.actionCreators.setIsLoading(action.recordType));
    
    try {
        const allResults = yield all(action.updatedRecords.map(record => {
            let url = `${getUrl(action.recordType)}/${record.id}`;
            if (record.rdtType === 'rdtSeriesYearEventClassContest' || record.rdtType === 'rdtSeriesYearEventClassDriver' || record.rdtType === 'rdtSeriesYearEventClass') {
                url = `${getUrl(action.recordType)}/${record.seriesYearEventId}/${record.id}`;
            }
            return axios.post(url, record).catch(error => error);
        }));
        if (allResults.some(x => x.isAxiosError)) {
            yield put(alertStore.actionCreators.pushAlert(`${allResults.filter(x => x.isAxiosError).length} API calls failed! ${allResults.filter(x => !x.isAxiosError).length} API calls succeeded!`, 'error'));
            //alert(`${allResults.filter(x => x.isAxiosError).length} API calls failed! ${allResults.filter(x => !x.isAxiosError).length} API calls succeeded!`);
        } else {
            yield put(alertStore.actionCreators.pushAlert(`${allResults.length} successful calls!`, 'success'));
            yield put(recordStore.actionCreators.setNeedsReload(action.recordType));
            //alert(`${allResults.length} successful calls!`);
        }
    } catch (err) {
        yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

function* watchDeleteRecord() {
    yield takeEvery(actionTypes.deleteRecordSaga, deleteRecordAsync);
}

function* deleteRecordAsync(action) {
    yield put(recordStore.actionCreators.setIsLoading(action.recordType));
    var url = `${getUrl(action.recordType)}/${action.updatedRecord.id}`;
    try {
        const res = yield call(fetch, url, { method: 'DELETE' });
        if (!res.ok) {
            yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, `Delete record API returned status code ${res.status} ${res.statusText}`));
        } else {
            yield put(recordStore.actionCreators.deleteRecordSuccess(action.recordType, action.recordId));
        }
    } catch (err) {
        yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

export const recordStoreSagaWatchers = (sagaMiddleware) => {
    sagaMiddleware.run(watchGetRecords);
    sagaMiddleware.run(watchCreateRecord);
    sagaMiddleware.run(watchUpdateRecords);
    sagaMiddleware.run(watchDeleteRecord);
}