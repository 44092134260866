import * as actionTypes from '../actionTypes';

const initialState = {
    unknownDrivers: [],
    isLoadedUnknownDrivers: false,
    isLoadingUnknownDrivers: false,
    isLoadErrorUnknownDrivers: false,
    loadErrorMessageUnknownDrivers: '',

    driverCandidates: []
};

export default (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.setIsLoadingUnknownDrivers:
            return {
                ...state,
                isLoadingUnknownDrivers: action.isLoading
            }

        case actionTypes.setIsLoadErrorUnknownDrivers:
            return {
                ...state,
                isLoadErrorUnknownDrivers: action.isLoadError,
                loadErrorMessageUnknownDrivers: action.loadErrorMessage
            }

        case actionTypes.receiveUnknownDrivers:
            return {
                ...state,
                unknownDrivers: action.records,
                isLoadedUnknownDrivers: true
            }

        case actionTypes.updateDriverCandidates:
            return {
                ...state,
                driverCandidates: action.candidates
            }

        default:
            return state;
    }
};