import { takeEvery, call } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';

function* watchQueueProcessEvent() {
    yield takeEvery(actionTypes.queueProcessEventSaga, queueProcessEventAsync);
}

function* queueProcessEventAsync(action) {
    var url = `/api/queues/process-event/${action.seriesCode}/${action.year}/${action.eventCode}`;
    yield sendQueueRequest(url);
}

function* watchQueueImportEventClassRound() {
    yield takeEvery(actionTypes.queueImportEventClassRoundSaga, queueImportEventClassRoundAsync);
}

function* queueImportEventClassRoundAsync(action) {
    var url = `/api/queues/import-event-class-round/${action.seriesCode}/${action.year}/${action.eventCode}/${action.classCode}/${action.roundType}/${action.roundNumber}`;
    yield sendQueueRequest(url);
}

function* watchQueueSetQualifyingOrder() {
    yield takeEvery(actionTypes.queueSetQualifyingOrderSaga, queueSetQualifyingOrderAsync);
}

function* queueSetQualifyingOrderAsync(action) {
    var url = `/api/queues/set-qualifying-order/${action.seriesCode}/${action.year}/${action.eventCode}/${action.classCode}/${action.roundType}/${action.roundNumber}`;
    yield sendQueueRequest(url);
}

function* sendQueueRequest(url) {
    try {
        var res = yield call(fetch, url);
        if (!res.ok) {
            // TODO: Add queueing to handle results that can be reported via toast popups
            //yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, `Get records API returned status code ${res.status} ${res.statusText}`));
        } else {
            //var data = yield res.json();
            //yield put(recordStore.actionCreators.receiveRecords(action.recordType, data));
        }
    } catch (err) {
        //yield put(recordStore.actionCreators.setIsLoadError(action.recordType, true, err.message));
    }
}

export const queueStoreSagaWatchers = (sagaMiddleware) => {
    sagaMiddleware.run(watchQueueProcessEvent);
    sagaMiddleware.run(watchQueueImportEventClassRound);
    sagaMiddleware.run(watchQueueSetQualifyingOrder);
}