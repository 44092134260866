import * as actionTypes from '../actionTypes';

export const actionCreators = {

    pushAlert: (message, variant) => dispatch => {
        dispatch({ type: actionTypes.pushAlert, message, variant });
    },

    removeAlert: (message, variant) => dispatch => {
        dispatch({ type: actionTypes.removeAlert, message, variant });
    }

}