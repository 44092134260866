import * as actionTypes from '../actionTypes';

export const actionCreators = {

    setNeedsReload: (recordType) => dispatch => {
        dispatch({ type: actionTypes.setNeedsReload, recordType });
    },

    setIsLoading: (recordType) => dispatch => {
        dispatch({ type: actionTypes.setIsLoading, recordType });
    },

    getRecords: (recordType, filter) => dispatch => {
        dispatch({ type: actionTypes.getRecordsSaga, recordType, filter });
    },

    receiveRecords: (recordType, records) => dispatch => {
        dispatch({ type: actionTypes.receiveRecords, recordType, records });
    },

    receiveSingleRecord: (recordType, record) => dispatch => {
        dispatch({ type: actionTypes.receiveSingleRecord, recordType, record });
    },

    setSelectedId: (recordType, selectedId) => dispatch => {
        dispatch({ type: actionTypes.setSelectedId, recordType, selectedId });
    },

    setIsLoadError: (recordType, isLoadError, loadErrorMessage) => dispatch => {
        dispatch({ type: actionTypes.setIsLoadError, recordType, isLoadError, loadErrorMessage });
    },

    createRecord: (recordType, record) => dispatch => {
        dispatch({ type: actionTypes.createRecordSaga, recordType, record });
    },

    createRecordSuccess: (recordType, record) => dispatch => {
        dispatch({ type: actionTypes.createRecordSuccess, recordType, record });
    },

    pushNewRecordId: (recordType, newRecordId) => dispatch => {
        dispatch({ type: actionTypes.pushNewRecordId, recordType, newRecordId });
    },

    popNewRecordId: (recordType) => dispatch => {
        dispatch({ type: actionTypes.popNewRecordId, recordType });
    },

    updateRecord: (recordType, updatedRecord) => dispatch => {
        dispatch({ type: actionTypes.updateRecordsSaga, recordType, updatedRecords: [updatedRecord] });
    },

    updateRecords: (recordType, updatedRecords) => dispatch => {
        dispatch({ type: actionTypes.updateRecordsSaga, recordType, updatedRecords });
    },

    deleteRecord: (recordType, recordId) => dispatch => {
        dispatch({ type: actionTypes.deleteRecordSaga, recordType, recordId });
    },

    deleteRecordSuccess: (recordType, recordId) => dispatch => {
        dispatch({ type: actionTypes.deleteRecordSuccess, recordType, recordId });
    }

}