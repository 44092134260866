import React from 'react';
import clsx from 'clsx';
import { AuthUserManager } from '../auth/AuthUserManager';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';
import { useSelector } from 'react-redux';
import * as settingsStore from '../../redux/settingsStore';
import * as authStore from '../../redux/authStore';
import 'typeface-roboto';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    title: {
        marginRight: '20px',
        flexGrow: 1
    },
    userName: {
        marginRight: '12px',
    },
    logo: {
        height: '36px',
        marginRight: '24px',
        marginTop: '4px'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: '18px'
    },
    hide: {
        display: 'none'
    },
    toolbarBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    toolbarTopRow: {
        display: 'flex'
    },
    toolbarBottomRow: {
        display: 'flex'
    }
}));

export const RdtAppBar = ({ open, setOpen }) => {

    const classes = useStyles();
    const location = useLocation();

    const useDarkTheme = useSelector(state => settingsStore.selectors.selectUseDarkTheme(state));

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));
    const userFirstName = useSelector(state => authStore.selectors.selectUserFirstName(state));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const getHeaderText = () => {

        if (location.pathname.startsWith('/app/users')) {
            return 'Users';
        }

        switch (location.pathname) {
            case '/app':
                return 'Home';

            case '/app/events':
                return 'Events';

            case '/app/add-drivers':
                return 'Add Drivers';

            case '/app/qualifying':
                return 'Qualifying';

            case '/app/qualifying-order':
                return 'Qualifying Order';

            case '/app/eliminations':
                return 'Eliminations';

            case '/app/settings':
                return 'Settings';

            case '/app/account':
                return 'My Account';

            default:
                return '';
        }
    }

    return (
        <AppBar position="fixed" color={clsx({ primary: useDarkTheme, secondary: !useDarkTheme })} className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
            <Toolbar>
                <IconButton edge="start" className={clsx(classes.menuButton, { [classes.hide]: open })} color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
                    <MenuIcon />
                </IconButton>
                <img className={classes.logo} src='/rdtportal3logo.png' alt='RDT Logo' />
                <Typography variant="h6" className={classes.title}>{getHeaderText()}</Typography>
                {isLoggedIn && userFirstName && <Typography className={classes.userName}>Welcome {userFirstName}!</Typography>}
                <AuthUserManager />
            </Toolbar>
        </AppBar>
    );
};