import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import * as qualifyingSummaryStore from '../../redux/qualifyingSummaryStore';
import { useDispatch, useSelector } from 'react-redux';

//const useStyles = makeStyles((theme) => ({

//}));

export const QualifyingSummaryTableSaveAlert = props => {

    //const classes = useStyles();
    const dispatch = useDispatch();

    const isAlertOpen = useSelector(state => qualifyingSummaryStore.selectors.selectIsSaveAlertOpen(state));
    const sortProperty = useSelector(state => qualifyingSummaryStore.selectors.selectSortProperty(state));
    const setIsAlertOpen = isOpen => dispatch(qualifyingSummaryStore.actionCreators.setIsSaveAlertOpen(isOpen));
    //const [isSaving, setIsSaving] = useState(false);
    const isSaving = false;

    const closeAlert = () => {
        if (!isSaving) {
            setIsAlertOpen(false);
        }
    }

    const performSave = () => {
        
    }

    return (
        <Dialog open={isAlertOpen} onClose={closeAlert}>
            <DialogTitle>Save Qualifying Results</DialogTitle>
            <DialogContent>
                <DialogContentText>This will save qualifying results based on [{sortProperty}]. Are you sure?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeAlert} disabled={isSaving}>Cancel</Button>
                <Button onClick={performSave} disabled={isSaving}>{isSaving ? 'SPIN_ICON_HERE' : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    );
}


//<Modal show={this.state.showSaveQualModal} onHide={this.closeSaveQualModal.bind(this)}>
//    <Modal.Header closeButton>
//        <Modal.Title>
//            <div>Save Qualifying Results</div>
//        </Modal.Title>
//    </Modal.Header>
//    <Modal.Body>
//        <Row>
//            <Col lg={12}>
//                <div>This will save qualifying results based on current sort column [{sortProperty}]. Are you sure?</div>
//            </Col>
//        </Row>
//        <Row>
//            <Col lg={12}>
//                <div>
//                    <Checkbox className={'jkp-no-select'} checked={this.state.closeOutRound} onChange={this.checkboxChange.bind(this)}>Close out this qualifying round?</Checkbox>
//                </div>
//            </Col>
//        </Row>
//    </Modal.Body>
//    <Modal.Footer>
//        <Button onClick={this.closeSaveQualModal.bind(this)} disabled={this.state.isSaving}>Cancel</Button>
//        <Button onClick={this.saveQual.bind(this)} disabled={this.state.isSaving} bsStyle='primary'>{this.state.isSaving ? <FontAwesomeIcon icon={faSpinner} pulse /> : 'Save'}</Button>
//    </Modal.Footer>
//</Modal>