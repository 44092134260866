import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as userStore from '../../redux/userStore';
//import { UserRow } from './UserRow';
//import { UserCell } from './UserCell';
//import { FixedSizeList, FixedSizeGrid } from 'react-window';
//import AutoSizer from 'react-virtualized-auto-sizer';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
//import ListItem from '@material-ui/core/ListItem';
//import ListItemText from '@material-ui/core/ListItemText';
import { useNavigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto'
    },
    headerRow: {
        gridRow: 1
    },
    tableRow: {
        gridRow: 2,
        overflowY: 'auto'
    },
    footerRow: {
        gridRow: 3
    },
}));

//const UserTableRow = props => {

//    const user = props.data[props.index];
//    const match = useMatch('/users/*userId');
//    const getName = () => {
//        const fullName = `${user.firstName} ${user.lastName}`.trim();
//        var output = user.name === '' ? fullName === '' ? user.email : fullName : user.name;
//        return output;
//    }

//    const getLinkTo = () => match?.userId === user.id ? '/users' : `/users/${user.id}`;

//    return (
//        <TableRow>
//            <ListItem style={props.style} button component={Link} to={getLinkTo()} selected={match?.userId === user.id}>
//                <ListItemText primary={user && getName()} />
//            </ListItem>
//        </TableRow>
//    );
//};

const getTableRow = (user, selectedUserId, navigate) => {

    const getName = () => {
        const fullName = `${user.firstName} ${user.lastName}`.trim();
        var output = user.name === '' ? fullName === '' ? user.email : fullName : user.name;
        return output;
    }

    const handleClick = (_e, userId) => {
        if (userId === selectedUserId) {
            navigate('/app/users');
        } else {
            navigate(`/app/users/${userId}`);
        }
    }

    return (
        <TableRow
            hover
            key={user.id}
            onClick={(e) => handleClick(e, user.id)}
            role="checkbox"
            aria-checked={user.id === selectedUserId}
            tabIndex={-1}
            selected={user.id === selectedUserId}>

            <TableCell>{getName()}</TableCell>
            {!selectedUserId &&
                [
                    <TableCell key={`tblUsersName_${user.id}`}>{user.name}</TableCell>,
                    <TableCell key={`tblUsersFirstName_${user.id}`}>{user.firstName}</TableCell>,
                    <TableCell key={`tblUsersLastName_${user.id}`}>{user.lastName}</TableCell>,
                    <TableCell key={`tblUsersEmail_${user.id}`}>{user.email}</TableCell>
                ]
            }
        </TableRow>
    );
}

//const getFixedSizeList = (users) => {
//    return (
//        <AutoSizer>
//            {({ height, width }) => (
//                <FixedSizeList height={height} itemCount={users.length} itemSize={40} width={width} itemData={users} itemKey={(index, data) => data[index].id}>
//                    {UserRow}
//                </FixedSizeList>
//            )}
//        </AutoSizer>
//    );
//}

//const getFixedSizeGrid = (users) => {
//    return (
//        <AutoSizer>
//            {({ height, width }) => (
//                <FixedSizeGrid columnCount={5} columnWidth={300} rowCount={users.length} height={height} rowHeight={40} width={width} itemData={users} itemKey={(columnIndex, data, rowIndex) => data?.[rowIndex].id}>
//                    {UserCell}
//                </FixedSizeGrid>
//            )}
//        </AutoSizer>
//    );
//}

export const UserList = () => {

    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);

    const selectedUserId = useSelector(state => userStore.selectors.selectSelectedUserId(state));

    const navigate = useNavigate();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const users = useSelector(state => userStore.selectors.selectUsers(state)).sort((a, b) => {
        const aFullName = `${a.firstName} ${a.lastName}`.trim();
        const aCalcName = a.name === '' ? aFullName === '' ? a.email : aFullName : a.name;
        const bFullName = `${b.firstName} ${b.lastName}`.trim();
        const bCalcName = b.name === '' ? bFullName === '' ? b.email : bFullName : b.name;
        if (aCalcName > bCalcName) return 1;
        if (aCalcName < bCalcName) return -1;
        return 0;
    });


    const getTableBody = users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x => getTableRow(x, selectedUserId, navigate));

    //const getTableBody = getFixedSizeList(users);

    return (
        <Box className={classes.tableContainer}>
            <Box className={classes.headerRow}>

            </Box>
            <Box className={classes.tableRow}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Calculated Name</TableCell>
                                {!selectedUserId &&
                                    [
                                        <TableCell key='tblUsersNameHeader'>Name</TableCell>,
                                        <TableCell key='tblUsersFirstNameHeader'>First Name</TableCell>,
                                        <TableCell key='tblUsersLastNameHeader'>Last Name</TableCell>,
                                        <TableCell key='tblUsersEmailHeader'>Email</TableCell>
                                    ]
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getTableBody}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box className={classes.footerRow}>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

//<AutoSizer>
//    {({ height, width }) => (
//        <FixedSizeList height={height} itemCount={users.length} itemSize={40} width={width} itemData={users} itemKey={(index, data) => data[index].id}>
//            {UserRow}
//        </FixedSizeList>
//    )}
//</AutoSizer>

//<AutoSizer>
//    {({ height, width }) => (
//        <FixedSizeGrid columnCount={5} columnWidth={300} rowCount={users.length} height={height} rowHeight={40} width={width} itemData={users} itemKey={(columnIndex, data, rowIndex) => data?.[rowIndex].id}>
//            {UserCell}
//        </FixedSizeGrid>
//    )}
//</AutoSizer>