import React from 'react';
import Box from '@material-ui/core/Box';
import * as qualifyingSummaryStore from '../../redux/qualifyingSummaryStore';
import { useSelector } from 'react-redux';
import { QualifyingSummaryTableSaveAlert } from './QualifyingSummaryTableSaveAlert';
import { QualifyingSummaryTableHeader } from './QualifyingSummaryTableHeader';
import { QualifyingSummaryTableDriverRow } from './QualifyingSummaryTableDriverRow';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginLeft: '12px',
        padding: '12px',
        background: '#222222',
        display: 'flex',
        flexDirection: 'column'
    },
    table: {
        width: '100%'
    },
    loadingSkeleton: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    loadingSkeletonHeader: {
        height: '120px',
        width: '50%'
    },
    loadingSkeletonBody: {
        flexGrow: '1',
        width: '100%'
    },
    qualifyingSummaryTableContainer: {
        flexGrow: '1',
        display: 'flex',
        overflowY: 'auto',
        minWidth: '1000px'
    }
}));

export const QualifyingSummaryTable = () => {

    const classes = useStyles();

    const tableData = useSelector(state => qualifyingSummaryStore.selectors.selectSortedTableData(state));
    const isLoading = useSelector(state => qualifyingSummaryStore.selectors.selectIsTableDataLoading(state));

    return (
        <Box className={classes.qualifyingSummaryTableContainer}>
            <QualifyingSummaryTableSaveAlert />
            <TableContainer component={Paper} className={classes.tableContainer}>
                {
                    isLoading ?
                        <Box className={classes.loadingSkeleton}>
                            <Skeleton animation='wave' variant='text' className={classes.loadingSkeletonHeader} />
                            <Skeleton animation='wave' variant='rect' className={classes.loadingSkeletonBody} />
                        </Box>
                        :
                        <Table className={clsx('qualifying-summary-table', classes.table)}>
                            <QualifyingSummaryTableHeader />
                            <TableBody>
                                {tableData.map(x => <QualifyingSummaryTableDriverRow row={x} key={`qual-summary-table-driver-row-${x.driverCode}`} />)}
                            </TableBody>
                        </Table>
                }
            </TableContainer>
        </Box>
    );
}