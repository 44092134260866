import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as alertStore from '../../redux/alertStore';

export const RdtSnackbarManager = () => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const fifoAlert = useSelector(state => alertStore.selectors.selectFifoAlert(state));
    const removeAlert = useCallback((message, variant) => dispatch(alertStore.actionCreators.removeAlert(message, variant)), [dispatch]);

    useEffect(() => {
        if (fifoAlert) {
            enqueueSnackbar(fifoAlert.message, { variant: fifoAlert.variant, persist: fifoAlert.variant === 'error' });
            removeAlert(fifoAlert.message, fifoAlert.variant);
        }
    }, [fifoAlert, removeAlert, enqueueSnackbar]);

    return <div />;

};