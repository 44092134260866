//import createCachedSelector from 're-reselect';
//import { createSelector } from 'reselect';

const selectUnknownDrivers = state => state.driverStore.unknownDrivers;
const selectIsLoadedUnknownDrivers = state => state.driverStore.isLoadedUnknownDrivers;
const selectIsLoadingUnknownDrivers = state => state.driverStore.isLoadingUnknownDrivers;
const selectIsLoadErrorUnknownDrivers = state => state.driverStore.isLoadErrorUnknownDrivers;
const selectLoadErrorMessageUnknownDrivers = state => state.driverStore.loadErrorMessageUnknownDrivers;

const selectDriverCandidates = state => state.driverStore.driverCandidates;

export const selectors = {
    selectUnknownDrivers,
    selectIsLoadedUnknownDrivers,
    selectIsLoadingUnknownDrivers,
    selectIsLoadErrorUnknownDrivers,
    selectLoadErrorMessageUnknownDrivers,

    selectDriverCandidates
}