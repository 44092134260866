import React from 'react';
import * as contextStore from '../../redux/contextStore';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    driverRow: {
        display: 'flex'
    },
    qualPos: {
        marginRight: '8px',
        width: '20px',
        color: 'cyan',
        textAlign: 'right'
    },
    driverName: {
        
    },
}));

export const EliminationsContestBoxDriver = ({ contestId, bracketOrder, className }) => {

    const classes = useStyles();

    const run = useSelector(state => contextStore.selectors.selectRunByBracketOrder(state, contestId, bracketOrder));
    const sds = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesDriver'));
    const sd = sds.find(x => x.id === run?.seriesDriverId);
    const syecds = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClassDriver'));
    const syecd = syecds.find(x => x.id === run?.seriesYearEventClassDriverId);

    return (
        <div className={clsx(classes.driverRow, className)}>
            <div className={classes.qualPos}>{syecd ? `${syecd?.qualifyingPosition}`.trim() : '-'}</div>
            <div className={classes.driverName}>{sd ? `${sd?.firstName} ${sd?.lastName}`.trim() : 'BYE'}</div>
        </div>
    );

};