import createCachedSelector from 're-reselect';
//import { createSelector } from 'reselect';
import * as recordStore from '../recordStore';
import * as contextStore from '../contextStore';

const selectIsSaveAlertOpen = state => state.qualifyingSummaryStore.isSaveAlertOpen;
const selectSortProperty = state => state.qualifyingSummaryStore.sortProperty;
const selectSortRound = state => state.qualifyingSummaryStore.sortRound;
const selectIsSortAscending = state => state.qualifyingSummaryStore.isSortAscending;

const selectTableData = createCachedSelector(
    state => contextStore.selectors.selectContestsForSelectedSeriesYearEventClass(state),
    state => contextStore.selectors.selectClassDriversForSelectedSeriesYearEventClass(state),
    state => contextStore.selectors.selectDriversForSelectedSeries(state),
    state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'),
    (contests, classDrivers, drivers, eventClass) => {
        if (!eventClass) {
            return [];
        }
        const numberOfRounds = Number(eventClass.qualifyingRounds);
        //console.log('re-re calc ' + count);
        //count += 1;
        var outputArray = classDrivers.map(d => {
            const driver = drivers.find(x => x.driverCode === d.driverCode);
            var output = {
                driverCode: d.driverCode,
                driverName: ((driver.firstName ? driver.firstName + ' ' : '') + (driver.lastName ? driver.lastName : '')).trim(),
                qualifyingPosition: d.qualifyingPosition,
                qualifyingSpeed: d.qualifyingSpeed,
                qualifyingTime: d.qualifyingTime,
                qualifyingDialIn: d.qualifyingDialIn,
                qualifyingOverUnderDialIn: d.qualifyingOverUnderDialIn,
                liveBestQualifyingSpeed: d.liveBestQualifyingSpeed,
                liveBestQualifyingTime: d.liveBestQualifyingTime,
                liveBestQualifyingDialIn: d.liveBestQualifyingDialIn,
                liveBestQualifyingOverUnderDialIn: d.liveBestQualifyingOverUnderDialIn,
                liveQualifyingPosition: d.liveQualifyingPosition,
                liveQualifyingSpeed: d.liveQualifyingSpeed,
                liveQualifyingTime: d.liveQualifyingTime,
                liveQualifyingDialIn: d.liveQualifyingDialIn,
                liveQualifyingOverUnderDialIn: d.liveQualifyingOverUnderDialIn
            };
            if (output.driverName === '') {
                output.driverName = output.driverCode;
            }
            let getContest = (roundString) => contests.find(c => c.roundType === 'q' && c.roundNumber === roundString && c.runs.some(r => r.driverCode === d.driverCode))
            for (var i = 0; i < numberOfRounds; i++) {
                var round = i + 1;
                var roundString = round.toString();

                output['q' + roundString + '-dq'] = false;
                output['q' + roundString + '-time'] = 0;
                output['q' + roundString + '-speed'] = 0;
                output['q' + roundString + '-dialIn'] = 0;
                output['q' + roundString + '-overUnderDialIn'] = 0;
                output['q' + roundString + '-display-time'] = '-';
                output['q' + roundString + '-display-speed'] = '-';
                output['q' + roundString + '-display-dialIn'] = '-';
                output['q' + roundString + '-display-overUnderDialIn'] = '-';
                output['q' + roundString + '-best-time'] = 0;
                output['q' + roundString + '-best-speed'] = 0;
                output['q' + roundString + '-best-dialIn'] = 0;
                output['q' + roundString + '-best-overUnderDialIn'] = 0;
                output['q' + roundString + '-display-best-time'] = '-';
                output['q' + roundString + '-display-best-speed'] = '-';
                output['q' + roundString + '-display-best-dialIn'] = '-';
                output['q' + roundString + '-display-best-overUnderDialIn'] = '-';
                output['q' + roundString + '-best-round'] = 0;

                let contest = getContest(roundString);
                if (contest != null) {
                    let run = contest.runs.find(r => r.driverCode === d.driverCode);
                    var time = 0;
                    var speed = 0;
                    switch (eventClass.distance) {
                        case '1320':
                            time = Number(run.time1320);
                            speed = Number(run.speed1320);
                            break;
                        case '1000':
                            time = Number(run.time1000);
                            speed = Number(run.speed1000);
                            break;
                        case '660':
                            time = Number(run.time660);
                            speed = Number(run.speed660);
                            break;
                        default:
                            time = Number(run.time1320);
                            speed = Number(run.speed1320);
                            break;
                    }
                    var dialIn = Number(run.dialIn);
                    var overUnderDialIn = Number(run.overUnderDialIn);
                    output['q' + roundString + '-dq'] = run.isDisqualified;
                    output['q' + roundString + '-time'] = time;
                    output['q' + roundString + '-speed'] = speed;
                    output['q' + roundString + '-dialIn'] = dialIn;
                    output['q' + roundString + '-overUnderDialIn'] = overUnderDialIn;
                    output['q' + roundString + '-display-time'] = time === 0 ? '-' : time.toFixed(3);
                    output['q' + roundString + '-display-speed'] = speed === 0 ? '-' : speed.toFixed(2);
                    output['q' + roundString + '-display-dialIn'] = dialIn === 0 ? '-' : dialIn.toFixed(3);
                    output['q' + roundString + '-display-overUnderDialIn'] = overUnderDialIn === 0 ? '-' : overUnderDialIn.toFixed(3);
                }

                var bestRound = null;

                for (var j = round; j > 0; j--) {
                    if (!output['q' + j.toString() + '-dq'] && output['q' + j.toString() + '-time'] !== 0) {
                        if (bestRound === null) {
                            if (!(eventClass.timingType === 'closestToDialIn' && output['q' + j.toString() + '-overUnderDialIn'] < 0)) {
                                bestRound = j;
                            }
                        } else {
                            if (eventClass.timingType === 'quickestTime') {
                                if (output['q' + j.toString() + '-time'] < output['q' + bestRound.toString() + '-time']) {
                                    bestRound = j;
                                } else if (output['q' + j.toString() + '-time'] === output['q' + bestRound.toString() + '-time'] && output['q' + j.toString() + '-speed'] > output['q' + bestRound.toString() + '-speed']) {
                                    bestRound = j;
                                }
                            }
                            if (eventClass.timingType === 'closestToDialIn') {
                                if (output['q' + j.toString() + '-overUnderDialIn'] < 0 && (output['q' + bestRound.toString() + '-overUnderDialIn'] < 0 && output['q' + j.toString() + '-overUnderDialIn'] > output['q' + bestRound.toString() + '-overUnderDialIn'])) {
                                    bestRound = j;
                                } else if (output['q' + j.toString() + '-overUnderDialIn'] >= 0 && (output['q' + bestRound.toString() + '-overUnderDialIn'] < 0 || output['q' + j.toString() + '-overUnderDialIn'] < output['q' + bestRound.toString() + '-overUnderDialIn'])) {
                                    bestRound = j;
                                } else if (output['q' + j.toString() + '-overUnderDialIn'] >= 0 && output['q' + j.toString() + '-overUnderDialIn'] === output['q' + bestRound.toString() + '-overUnderDialIn'] && output['q' + j.toString() + '-speed'] > output['q' + bestRound.toString() + '-speed']) {
                                    bestRound = j;
                                }
                            }
                            if (eventClass.timingType === 'quickestAgainstDialIn') {
                                if (output['q' + j.toString() + '-overUnderDialIn'] < output['q' + bestRound.toString() + '-overUnderDialIn']) {
                                    bestRound = j;
                                } else if (output['q' + j.toString() + '-overUnderDialIn'] === output['q' + bestRound.toString() + '-overUnderDialIn'] && output['q' + j.toString() + '-speed'] > output['q' + bestRound.toString() + '-speed']) {
                                    bestRound = j;
                                }
                            }
                        }
                    }
                }

                if (bestRound != null) {
                    var bestRoundString = bestRound.toString();

                    output['q' + roundString + '-best-time'] = output['q' + bestRoundString + '-time'];
                    output['q' + roundString + '-best-speed'] = output['q' + bestRoundString + '-speed'];
                    output['q' + roundString + '-best-dialIn'] = output['q' + bestRoundString + '-dialIn'];
                    output['q' + roundString + '-best-overUnderDialIn'] = output['q' + bestRoundString + '-overUnderDialIn'];
                    output['q' + roundString + '-display-best-time'] = output['q' + bestRoundString + '-display-time'];
                    output['q' + roundString + '-display-best-speed'] = output['q' + bestRoundString + '-display-speed'];
                    output['q' + roundString + '-display-best-dialIn'] = output['q' + bestRoundString + '-display-dialIn'];
                    output['q' + roundString + '-display-best-overUnderDialIn'] = output['q' + bestRoundString + '-display-overUnderDialIn'];
                    output['q' + roundString + '-best-round'] = bestRound;
                }

            }
            return output;
        });
        return outputArray.map((x, idx) => {
            return { ...x, sortOrder: (idx + 1) };
        });
    }
)(
    state => `${recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')}`
);

export const selectSortedTableData = createCachedSelector(
    selectTableData,
    state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'),
    selectSortProperty,
    selectSortRound,
    selectIsSortAscending,
    (summary, eventClass, sortProperty, sortRound, isAscending) => {
        var sortDirection = isAscending ? 'a' : 'd';

        if (sortProperty === '') return summary;
        var newArray = [...summary];
        if (eventClass) {
            if (sortProperty === 'driver') {
                newArray.sort((a, b) => {
                    return (a.driverCode < b.driverCode) ? 1 : (a.driverCode > b.driverCode) ? -1 : 0;
                });
                //} else if (sortProperty === 'live') {
                //    newArray.sort((a, b) => {
                //        return (a.liveQualifyingPosition < b.liveQualifyingPosition) ? 1 : (a.liveQualifyingPosition > b.liveQualifyingPosition) ? -1 : 0;
                //    });
                //} else if (sortProperty === 'prior') {
                //    newArray.sort((a, b) => {
                //        return (a.qualifyingPosition < b.qualifyingPosition) ? 1 : (a.qualifyingPosition > b.qualifyingPosition) ? -1 : 0;
                //    });
            } else if (eventClass.timingType === 'closestToDialIn' && sortProperty.endsWith('-overUnderDialIn')) {
                sortProperty = `q${sortRound}-${sortProperty}`;
                newArray.sort((a, b) => {
                    var temp = '-best-time';
                    if (sortProperty.indexOf('best') === -1) {
                        temp = '-time';
                    }
                    if (sortProperty.endsWith('-overUnderDialIn') && a[sortProperty] === 0 && a['q' + sortRound + temp] === 0 && b['q' + sortRound + temp] !== 0) {
                        return 1;
                    }
                    if (sortProperty.endsWith('-overUnderDialIn') && b[sortProperty] === 0 && b['q' + sortRound + temp] === 0 && a['q' + sortRound + temp] !== 0) {
                        return -1;
                    }
                    if (sortProperty.indexOf('best') === -1 && a['q' + sortRound + '-dq'] !== b['q' + sortRound + '-dq']) {
                        if (a['q' + sortRound + '-dq']) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    if (a[sortProperty] === b[sortProperty]) {
                        var newSortProperty = sortProperty.replace('-overUnderDialIn', '-speed');
                        return (a[newSortProperty] < b[newSortProperty]) ? 1 : (a[newSortProperty] > b[newSortProperty]) ? -1 : 0;
                    }
                    if (a[sortProperty] < 0 && b[sortProperty] >= 0) {
                        return 1;
                    }
                    if (a[sortProperty] >= 0 && b[sortProperty] < 0) {
                        return -1;
                    }
                    if (a[sortProperty] >= 0 && b[sortProperty] >= 0) {
                        return (a[sortProperty] < b[sortProperty]) ? -1 : (a[sortProperty] > b[sortProperty]) ? 1 : 0;
                    }
                    if (a[sortProperty] < 0 && b[sortProperty] < 0) {
                        return (a[sortProperty] < b[sortProperty]) ? 1 : (a[sortProperty] > b[sortProperty]) ? -1 : 0;
                    }
                    return 0;
                });
            } else {
                sortProperty = `q${sortRound}-${sortProperty}`;
                newArray.sort((a, b) => {
                    var temp = '-best-time';
                    if (sortProperty.indexOf('best') === -1) {
                        temp = '-time';
                    }
                    if (sortProperty.endsWith('-overUnderDialIn') && a[sortProperty] === 0 && a['q' + sortRound + temp] === 0 && b['q' + sortRound + temp] !== 0) {
                        return 1;
                    }
                    if (sortProperty.endsWith('-overUnderDialIn') && b[sortProperty] === 0 && b['q' + sortRound + temp] === 0 && a['q' + sortRound + temp] !== 0) {
                        return -1;
                    }
                    if (sortProperty.indexOf('best') === -1 && a['q' + sortRound + '-dq'] !== b['q' + sortRound + '-dq']) {
                        if (a['q' + sortRound + '-dq']) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else if (sortProperty.endsWith('-time') && a[sortProperty] === b[sortProperty]) {
                        var newSortProperty = sortProperty.replace('-time', '-speed');
                        return (a[newSortProperty] < b[newSortProperty]) ? 1 : (a[newSortProperty] > b[newSortProperty]) ? -1 : 0;
                    } else if (sortProperty.endsWith('-time') && (a[sortProperty] === 0 || b[sortProperty] === 0)) {
                        return a[sortProperty] === 0 ? 1 : -1;
                    } else {
                        return (a[sortProperty] < b[sortProperty]) ? -1 : (a[sortProperty] > b[sortProperty]) ? 1 : 0;
                    }
                });
            }
            if (sortDirection === 'd') {
                newArray.reverse();
            }
        }
        return newArray.map((x, idx) => {
            return { ...x, sortOrder: (idx + 1) };
        });
    }
)(
    state => `${recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')}`
);

export const selectIsTableDataLoading = createCachedSelector(
    selectTableData,
    state => contextStore.selectors.selectClassDriversForSelectedSeriesYearEventClass(state),
    state => recordStore.selectors.selectIsLoading(state, 'rdtSeriesYearEventClassDriver'),
    state => recordStore.selectors.selectIsLoading(state, 'rdtSeriesYearEventClassContest'),
    (tableData, classDrivers, isSyecdLoading, isSyeccLoading) => {
        return isSyecdLoading || isSyeccLoading || (tableData.length === 0 && classDrivers.length > 0);
    }
)(
    state => `${recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass')}`
);

export const selectors = {
    selectIsSaveAlertOpen,
    selectSortProperty,
    selectSortRound,
    selectIsSortAscending,
    selectSortedTableData,
    selectIsTableDataLoading
}