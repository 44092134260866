import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

const selectRecords = (state, recordType) => state.recordStore[recordType].records;
const selectIsLoaded = (state, recordType) => state.recordStore[recordType].isLoaded;
const selectIsLoading = (state, recordType) => state.recordStore[recordType].isLoading;
const selectIsLoadError = (state, recordType) => state.recordStore[recordType].isLoadError;
const selectLoadErrorMessage = (state, recordType) => state.recordStore[recordType].loadErrorMessage;
const selectSelectedId = (state, recordType) => state.recordStore[recordType].selectedId;
const selectNewRecordId = (state, recordType) => state.recordStore[recordType].newRecordId;
const selectNeedsReload = (state, recordType) => state.recordStore[recordType].needsReload;

const selectRecordById = createCachedSelector(
    [
        selectRecords,
        (_state, _recordType, id) => id
    ],
    (records, id) => records.find(x => x.id === id)
)(
    (_state, _recordType, id) => id
);

const selectSelectedRecord = createSelector(
    [
        selectRecords,
        selectSelectedId
    ],
    (records, id) => records.find(x => x.id === id)
);

const selectRecordByIdExists = createCachedSelector(
    [
        selectRecords,
        (_state, _recordType, id) => id
    ],
    (records, id) => records.some(x => x.id === id)
)(
    (_state, _recordType, id) => id
);

export const selectors = {
    selectRecords,
    selectIsLoaded,
    selectIsLoading,
    selectIsLoadError,
    selectLoadErrorMessage,
    selectSelectedId,
    selectRecordById,
    selectSelectedRecord,
    selectRecordByIdExists,
    selectNewRecordId,
    selectNeedsReload
}