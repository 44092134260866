import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as driverStore from '../../redux/driverStore';
import * as recordStore from '../../redux/recordStore';
import * as validators from '../../redux/validators';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Row, TextEditor } from '@tns/react-data-grid';
import clsx from 'clsx';
import { PageContentPresenter } from '../container/PageContentPresenter';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column'
    }
}));

export const SchedulePage = () => {

    const classes = useStyles();

    return (
        <PageContentPresenter showBreadcrumbs={false}>
            <Box className={classes.container}>
                <Typography>{'Placeholder'}</Typography>
            </Box>
        </PageContentPresenter>
    );
};