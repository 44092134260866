import * as actionTypes from '../actionTypes';

const initialState = {
    hasCheckedUserInfo: false,
    isLoggedIn: false,
    rdtUserId: '',
    userFirstName: '',
    userLastName: '',
    userFullName: '',
    userEmail: '',
    accessToken: '',
    idToken: '',
    claims: []
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.receiveAuthUserInfo:
            return {
                ...state,
                hasCheckedUserInfo: true,
                isLoggedIn: action.isLoggedIn,
                rdtUserId: action.rdtUserId,
                accessToken: action.accessToken,
                idToken: action.idToken,
                claims: action.claims,
                userFirstName: action.userFirstName,
                userLastName: action.userLastName,
                userFullName: action.userFullName,
                userEmail: action.userEmail
            } 

        default:
            return state;
    }
};