import React from 'react';
import { UserInfo } from './UserInfo';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    gridButtonRow: {
        gridRow: 1,
        minHeight: '48px',
        display: 'flex',
        justifyContent: 'flex-end',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: '5px 5px 0 0'
    },
    gridListRow: {
        gridRow: 3,
        margin: '12px'
    },
    dividerRow: {
        gridRow: 2,
    },
    tableHeaderLabel: {
        flexGrow: 1,
    },
    paper: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto auto 1fr'
    },
    rotate: {
        animation: 'rotation 2s infinite linear'
    }
}));

export const UserInfoPanel = () => {

    const classes = useStyles();
    
    return (
        <Paper elevation={5} className={classes.paper}>
            <Toolbar className={classes.gridButtonRow}>
                <Typography className={classes.tableHeaderLabel}>USER INFO</Typography>
            </Toolbar>
            <Divider className={classes.dividerRow} />
            <Box className={classes.gridListRow}>
                <UserInfo />
            </Box>
        </Paper>
    );
};