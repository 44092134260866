import * as actionTypes from '../actionTypes';

export const actionCreators = {

    setIsLoadingUnknownDrivers: (isLoading) => dispatch => {
        dispatch({ type: actionTypes.setIsLoadingUnknownDrivers, isLoading });
    },

    getUnknownDrivers: (seriesCode, year, eventCode, classCode) => dispatch => {
        dispatch({ type: actionTypes.getUnknownDriversSaga, seriesCode, year, eventCode, classCode });
    },

    receiveUnknownDrivers: (records) => dispatch => {
        dispatch({ type: actionTypes.receiveUnknownDrivers, records });
    },

    setIsLoadErrorUnknownDrivers: (isLoadError, loadErrorMessage) => dispatch => {
        dispatch({ type: actionTypes.setIsLoadErrorUnknownDrivers, isLoadError, loadErrorMessage });
    },

    processUnknownDrivers: () => dispatch => {
        dispatch({ type: actionTypes.processUnknownDriversSaga });
    },

    updateDriverCandidates: (candidates) => dispatch => {
        dispatch({ type: actionTypes.updateDriverCandidates, candidates });
    },

    saveDriverCandidates: () => dispatch => {
        dispatch({ type: actionTypes.saveDriverCandidatesSaga });
    }

}