import React from 'react';
import Box from '@material-ui/core/Box';
import * as userStore from '../../redux/userStore';
import { useSelector } from 'react-redux';

export const UserInfo = props => {

    //const userId = useSelector(state => userStore.selectors.selectSelectedUserId(state));
    //const user = useSelector(state => userStore.selectors.selectUserById(state, userId));

    const user2 = useSelector(state => userStore.selectors.selectSelectedUser(state));

    return (
        <Box>
            {user2 &&
                <Box>
                    <Box>{user2.name}</Box>
                    <Box>{user2.firstName}</Box>
                    <Box>{user2.lastName}</Box>
                    <Box>{user2.email}</Box>
                </Box>
            }
        </Box>
    );
};