import * as actionTypes from '../actionTypes';

const initialState = {
    alertQueue: []
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.pushAlert:
            return {
                ...state,
                alertQueue: [...state.alertQueue, { message: action.message, variant: action.variant }]
            }

        case actionTypes.removeAlert:
            const newQueue = [...state.alertQueue];
            newQueue.splice(newQueue.findIndex(x => x.message === action.message && x.variant === action.variant), 1);
            return {
                ...state,
                alertQueue: newQueue
            }

        default:
            return state;
    }
};