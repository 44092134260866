import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import LoadUnknownDriversIcon from '@material-ui/icons/GetApp';
import * as driverStore from '../../redux/driverStore';
import * as recordStore from '../../redux/recordStore';
import * as validators from '../../redux/validators';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    buttonRow: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

export const DriversTableToolbarRow = () => {

    const classes = useStyles();

    const selectedSeriesYearEventClass = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))
    const selectedSeriesYearEventClassId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass'))
    const candidatesCanBeSaved = useSelector(state => validators.driverCandidates.validateDriverCandidateAll(state));
    
    const dispatch = useDispatch();
    const saveDrivers = () => dispatch(driverStore.actionCreators.saveDriverCandidates());
    const resetCandidates = () => dispatch(driverStore.actionCreators.updateDriverCandidates([]));
    const loadUnknownDrivers = () => dispatch(driverStore.actionCreators.getUnknownDrivers(selectedSeriesYearEventClass.seriesCode, selectedSeriesYearEventClass.year, selectedSeriesYearEventClass.eventCode, selectedSeriesYearEventClass.classCode));

    return (
        <Box className={classes.buttonRow}>
            <Toolbar>
                <IconButton disabled={selectedSeriesYearEventClassId === ''} onClick={loadUnknownDrivers}>
                    <LoadUnknownDriversIcon />
                </IconButton>
                <IconButton disabled={!candidatesCanBeSaved} onClick={saveDrivers}>
                    <SaveIcon />
                </IconButton>
                <IconButton edge='end' onClick={resetCandidates}>
                    <RefreshIcon />
                </IconButton>
            </Toolbar>
        </Box>
    );
};