import * as actionTypes from '../actionTypes';

const initialState = {
    isConnected: false
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.setSignalRConnectionStatus:
            return {
                ...state,
                isConnected: action.isConnected
            }

        default:
            return state;
    }
};