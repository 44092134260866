import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as recordStore from '../../redux/recordStore';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    buttonRow: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

export const QualifyingOrderTableToolbarRow = ({ isDirty, tableData }) => {

    const classes = useStyles();
   
    const dispatch = useDispatch();
    const saveQual = () => dispatch(recordStore.actionCreators.updateRecords('rdtSeriesYearEventClassDriver', tableData));

    return (
        <Box className={classes.buttonRow}>
            <Toolbar>
                <IconButton disabled={!isDirty} onClick={() => saveQual()}>
                    <SaveIcon />
                </IconButton>
                <IconButton edge='end' disabled={true}>
                    <RefreshIcon />
                </IconButton>
            </Toolbar>
        </Box>
    );
};