import * as actionTypes from '../actionTypes';

const initialState = {
    useDarkTheme: true
};

export default (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.setUseDarkTheme:
            return {
                ...state,
                useDarkTheme: action.useDarkTheme
            }

        default:
            return state;
    }
};