import React from 'react';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { EliminationsRoundColumn } from './EliminationsRoundColumn';
import { PageContentPresenter } from '../container/PageContentPresenter';

const useStyles = makeStyles(() => ({
    ladderOuterContainer: {
        flexGrow: 1,
        height: 'fit-content',
        paddingTop: '8px',
        paddingBottom: '16px',
        display: 'flex'
    },
    ladderInnerContainer: {
        display: 'flex',
        flexGrow: '1',
        marginLeft: '24px',
        marginRight: '24px'
    },
    container: {
        display: 'flex',
        flexGrow: '1',
        overflowY: 'auto',
        flexDirection: 'column'
    },
    placeholder: {
        //flexGrow: '1'
    },
    selectClassContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center'
    },
    selectClassText: {
        //marginBottom: '24px'
    },
}));

export const EliminationsPage = () => {

    const classes = useStyles();

    const syec = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'))
    const rounds = Number(syec?.eliminationRounds);
    const fieldSize = Number(syec?.maxFieldSize);
    const lanes = Number(syec?.lanes);

    const getColumns = () => {
        let cols = [];
        for (let i = 1; i <= rounds; i++) {
            let contests = fieldSize / ((2 ** (i - 1)) * lanes);
            cols.push(<EliminationsRoundColumn contestCount={contests} roundNumber={i} key={`elim-column-round-${i}`} />);
        }
        return cols;
    }

    return (
        <PageContentPresenter showBreadcrumbs={true} selectionRequiredCondition={syec} selectionRequiredText={'Please select an event class'}>
            <Box className={classes.container}>
                <Paper elevation={3} className={classes.ladderOuterContainer}>
                    <Box className={classes.ladderInnerContainer}>
                        {getColumns()}
                    </Box>
                </Paper>
                <Box className={classes.placeholder} />
            </Box>
        </PageContentPresenter>
    );
};