//import createCachedSelector from 're-reselect';
//import { createSelector } from 'reselect';
//import * as authStore from '../authStore';

const selectIsLoggedIn = state => state.authStore.isLoggedIn;
const selectRdtUserId = state => state.authStore.rdtUserId;
const selectUserFirstName = state => state.authStore.userFirstName;
const selectAccessToken = state => state.authStore.accessToken;
const selectIdToken = state => state.authStore.idToken;
const selectClaims = state => state.authStore.claims;
const selectHasCheckedUserInfo = state => state.authStore.hasCheckedUserInfo;

export const selectors = {
    selectIsLoggedIn,
    selectRdtUserId,
    selectUserFirstName,
    selectAccessToken,
    selectIdToken,
    selectClaims,
    selectHasCheckedUserInfo
}