import { useEffect, useCallback, useState } from 'react';
import * as recordStore from '../../redux/recordStore';
import * as authStore from '../../redux/authStore';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, navigate } from '@reach/router';
import qs from 'query-string';

export const UrlQueryStringManager = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));

    const series = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeries')).sort((a, b) => a.shortName > b.shortName ? 1 : -1);
    const isLoadedS = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeries'));
    const selectedSeries = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeries'));
    const saveSelectedSeriesId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeries', id)), [dispatch]);


    const seriesYearsAll = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesYear'));
    const isLoadedSy = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesYear'));
    const selectedSeriesYear = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYear'));
    const saveSelectedSeriesYearId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYear', id)), [dispatch]);

    const seriesYearEventsAll = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEvent'));
    const isLoadedSye = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesYearEvent'));
    const selectedSeriesYearEvent = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEvent'));
    const saveSelectedSeriesYearEventId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYearEvent', id)), [dispatch]);

    const seriesYearEventClassesAll = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesYearEventClass'));
    const isLoadedSyec = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesYearEventClass'));
    const selectedSeriesYearEventClass = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEventClass'));
    const saveSelectedSeriesYearEventClassId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYearEventClass', id)), [dispatch]);

    const [isInitializedFromUrlQuery, setIsInitializedFromUrlQuery] = useState(false);
    const [seriesCode, setSeriesCode] = useState('');
    const [year, setYear] = useState('');
    const [eventCode, setEventCode] = useState('');
    const [classCode, setClassCode] = useState('');

    const getQueryString = useCallback(() => {
        let q = '';
        if (seriesCode) {
            q = `?seriesCode=${seriesCode}`;
            if (year) {
                q += `&year=${year}`;
                if (eventCode) {
                    q += `&eventCode=${eventCode}`;
                    if (classCode) {
                        q += `&classCode=${classCode}`;
                    }
                }
            }
        }
        return q;
    }, [seriesCode, year, eventCode, classCode]);

    const updateUrl = useCallback(q => {
        if (q !== location.search) {
            const newUrl = `${location.origin}${location.pathname}${q}`;
            if (location.href !== newUrl) {
                navigate(newUrl, { replace: true });
            }
        }
    }, [location]);

    const [initialQueryString, setInitialQueryString] = useState('');

    useEffect(() => {
        if (!isLoggedIn) {
            if (!initialQueryString && location.search) {
                setInitialQueryString(location.search);
            }
            if (initialQueryString && !location.search) {
                updateUrl(initialQueryString);
            }
        } else {
            setInitialQueryString('');
        }
    }, [isLoggedIn, initialQueryString, location, updateUrl]);

    useEffect(() => {
        if (isLoggedIn) {
            if (isInitializedFromUrlQuery) {
                if (!selectedSeriesYearEvent) {
                    setClassCode('');
                    saveSelectedSeriesYearEventClassId('');
                }
                if (!selectedSeriesYear) {
                    setEventCode('');
                    saveSelectedSeriesYearEventId('');
                }
                if (!selectedSeries) {
                    setYear('');
                    saveSelectedSeriesYearId('');
                }
                if (isLoadedS && selectedSeries) {
                    setSeriesCode(selectedSeries.seriesCode);
                    if (isLoadedSy && selectedSeriesYear && selectedSeriesYear.seriesId === selectedSeries.id) {
                        setYear(selectedSeriesYear.year);
                        if (isLoadedSye && selectedSeriesYearEvent && selectedSeriesYearEvent.seriesId === selectedSeries.id && selectedSeriesYearEvent.seriesYearId === selectedSeriesYear.id) {
                            setEventCode(selectedSeriesYearEvent.eventCode);
                            if (isLoadedSyec && selectedSeriesYearEventClass && selectedSeriesYearEventClass.seriesId === selectedSeries.id && selectedSeriesYearEventClass.seriesYearId === selectedSeriesYear.id && selectedSeriesYearEventClass.seriesYearEventId === selectedSeriesYearEvent.id) {
                                setClassCode(selectedSeriesYearEventClass.classCode);
                            } else {
                                setClassCode('');
                                saveSelectedSeriesYearEventClassId('');
                            }
                        } else {
                            setEventCode('');
                            saveSelectedSeriesYearEventId('');
                        }
                    } else {
                        setYear('');
                        saveSelectedSeriesYearId('');
                    }
                } else {
                    setSeriesCode('');
                    saveSelectedSeriesId('');
                }
                updateUrl(getQueryString());
            } else {
                if (isLoadedS && isLoadedSy && isLoadedSye && isLoadedSyec) {
                    const q = qs.parse(location.search);
                    if (q.seriesCode) {
                        const s = series.find(x => x.seriesCode === q.seriesCode);
                        if (s) {
                            saveSelectedSeriesId(s.id);
                            setSeriesCode(s.seriesCode);
                            if (q.year) {
                                const sy = seriesYearsAll.find(x => x.seriesCode === q.seriesCode && x.year === q.year);
                                if (sy) {
                                    saveSelectedSeriesYearId(sy.id);
                                    setYear(sy.year);
                                    if (q.eventCode) {
                                        const sye = seriesYearEventsAll.find(x => x.seriesCode === q.seriesCode && x.year === q.year && x.eventCode === q.eventCode);
                                        if (sye) {
                                            saveSelectedSeriesYearEventId(sye.id);
                                            setEventCode(sye.eventCode);
                                            if (q.classCode) {
                                                const syec = seriesYearEventClassesAll.find(x => x.seriesCode === q.seriesCode && x.year === q.year && x.eventCode === q.eventCode && x.classCode === q.classCode);
                                                if (syec) {
                                                    saveSelectedSeriesYearEventClassId(syec.id);
                                                    setClassCode(syec.classCode);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    };
                    setIsInitializedFromUrlQuery(true);
                }
            }
        }
    },
        [
            saveSelectedSeriesId,
            saveSelectedSeriesYearId,
            saveSelectedSeriesYearEventId,
            saveSelectedSeriesYearEventClassId,
            isInitializedFromUrlQuery,
            selectedSeries,
            selectedSeriesYear,
            selectedSeriesYearEvent,
            selectedSeriesYearEventClass,
            isLoadedS,
            isLoadedSy,
            isLoadedSye,
            isLoadedSyec,
            updateUrl,
            series,
            seriesYearsAll,
            seriesYearEventsAll,
            seriesYearEventClassesAll,
            location,
            isLoggedIn,
            getQueryString
        ]
    );

    return [];

}