import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as driverStore from '../../redux/driverStore';
import * as recordStore from '../../redux/recordStore';
import * as validators from '../../redux/validators';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid, { Row, TextEditor } from '@tns/react-data-grid';
//import './react-data-grid.css';
//import AutoSizer from 'react-virtualized-auto-sizer';
import { DriversTableToolbarRow } from './DriversTableToolbarRow';
import { DriversTableValidationCell } from './DriversTableValidationCell';
import clsx from 'clsx';
import { PageContentPresenter } from '../container/PageContentPresenter';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexGrow: '1',
        flexDirection: 'column'
    },
    tableRow: {
        flexGrow: '1',
        minWidth: '500px',
        display: 'flex'
    },
    newRow: {
        color: '#ffffff44',
        fontStyle: 'italic'
    },
    dataGrid: {
        width: '95%'
    }
}));

export const DriversPage = () => {

    const classes = useStyles();

    const isLoadedDrivers = useSelector(state => recordStore.selectors.selectIsLoaded(state, 'rdtSeriesDriver'));
    const isLoadingDrivers = useSelector(state => recordStore.selectors.selectIsLoading(state, 'rdtSeriesDriver'));
    const candidates = useSelector(state => driverStore.selectors.selectDriverCandidates(state));
    const sId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeries'))

    const dispatch = useDispatch();
    const updateCandidates = (updatedCandidates) => dispatch(driverStore.actionCreators.updateDriverCandidates(updatedCandidates));

    const [tableData, setTableData] = useState([]);

    const getNewRow = () => {
        return {
            id: -1,
            unknownDriverId: -1,
            firstName: '',
            lastName: '',
            fullName: '',
            promoterCode: '',
            driverCode: 'Insert new driver...'
        }
    }

    useEffect(() => {
        if (isLoadedDrivers && !isLoadingDrivers) {
            const newData = [
                ...candidates,
                getNewRow()
            ];
            setTableData(newData);
        }
    }, [candidates, isLoadedDrivers, isLoadingDrivers]);

    // The datagrid is supposed to send the change data as a second parameter, but it is null
    const handleRowsUpdated = updatedRows => {
        var existingRows = updatedRows.filter(x => x.id !== -1);
        var newRow = updatedRows.find(x => x.id === -1);

        if (newRow.driverCode !== 'Insert new driver...' || newRow.firstName !== '' || newRow.lastName !== '' || newRow.fullName !== '' || newRow.promoterCode !== '') {
            newRow.id = updatedRows.length - 1;
            if (newRow.driverCode === 'Insert new driver...') {
                newRow.driverCode = '';
            }
            existingRows.push(newRow);
        }

        updateCandidates(existingRows);
    }

    const customRowRenderer = (props) => {
        return <Row {...props} className={clsx({ [classes.newRow]: props.row.id === -1 })} />;
    }

    const columns = [
        {
            key: 'driverCode',
            name: 'Driver Code',
            editor: TextEditor,
            formatter: (props) => <DriversTableValidationCell {...props} propertyName='driverCode' validator={validators.driverCandidates.validateDriverCandidateDriverCodeCell} />
        },
        {
            key: 'fullName',
            name: 'Full Name',
            editor: TextEditor,
        },
        {
            key: 'promoterCode',
            name: 'Promoter Code',
            editor: TextEditor,
            formatter: (props) => <DriversTableValidationCell {...props} propertyName='promoterCode' validator={validators.driverCandidates.validateDriverCandidatePromoterCodeCell} />
        },
        {
            key: 'firstName',
            name: 'First Name',
            editor: TextEditor,
            formatter: (props) => <DriversTableValidationCell {...props} propertyName='firstName' validator={validators.driverCandidates.validateDriverCandidateFirstNameCell} />
        },
        {
            key: 'lastName',
            name: 'Last Name',
            editor: TextEditor,
            formatter: (props) => <DriversTableValidationCell {...props} propertyName='lastName' validator={validators.driverCandidates.validateDriverCandidateLastNameCell} />
        }
    ];

    return (
        <PageContentPresenter showBreadcrumbs={true} selectionRequiredCondition={sId} selectionRequiredText={'Please select a series'}>
            <Box className={classes.container}>
                <DriversTableToolbarRow />
                <Box className={classes.tableRow}>
                    <DataGrid
                        enableCellSelect={true}
                        className={classes.dataGrid}
                        rowRenderer={customRowRenderer}
                        rowKeyGetter='id'
                        onRowsChange={handleRowsUpdated}
                        cellNavigationMode='changeRow'
                        columns={columns}
                        rows={tableData} />
                </Box>
            </Box>
        </PageContentPresenter>
    );
};

//<AutoSizer>
//    {({ height, width }) => (
//        <DataGrid
//            height={height}
//            width={width}
//            enableCellSelect={true}
//            rowRenderer={customRowRenderer}
//            rowKey='id'
//            onRowsUpdate={handleRowsUpdated}
//            cellNavigationMode='changeRow'
//            columns={columns}
//            rows={tableData} />
//    )}
//</AutoSizer>
