import React from 'react';
import * as contextStore from '../../redux/contextStore';
import * as recordStore from '../../redux/recordStore';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { EliminationsContestBox } from './EliminationsContestBox';

const useStyles = makeStyles(() => ({
    allColumns: {
        display: 'flex',
        flexGrow: 1
    },
    roundColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        flexGrow: 1
    },
    priorRoundOutlineColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    priorRoundOutline: {
        borderTop: '2px solid #333333',
        borderRight: '2px solid #333333',
        borderBottom: '2px solid #333333',
        width: '24px',
        flexGrow: 1
    },
    priorRoundOutlineSpacer: {
        flexGrow: 1
    },
    priorRoundOutlineSpacerHalf: {
        flexGrow: '0.5'
    },
    nextRoundOutlineColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    nextRoundOutline: {
        borderTop: '2px solid #333333',
        width: '24px',
    }
}));

export const EliminationsRoundColumn = ({ roundNumber, contestCount }) => {

    const classes = useStyles();

    const syecc = useSelector(state => contextStore.selectors.selectEliminationContestsForSelectedSeriesYearEventClass(state));
    const syecId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass'));

    const getContestBoxes = () => {
        let boxes = [];
        for (let i = 1; i <= contestCount; i++) {
            let contest = syecc.find(x => x.roundNumber === roundNumber.toString() && x.bracketOrder === i.toString());
            boxes.push(<EliminationsContestBox contestId={contest?.id} bracketOrder={i} roundNumber={roundNumber} key={`${syecId}-elim-round-${roundNumber}-contest-${i}`} />);
        }
        return boxes;
    }

    const getNextRoundOutlines = () => {
        let boxes = [];
        for (let i = 1; i <= contestCount; i++) {
            boxes.push(<div className={classes.nextRoundOutline} key={`${syecId}-next-round-outline-${roundNumber}-box-${i}`} />);
        }
        return boxes;
    }

    const getPriorRoundOutlines = () => {
        let boxes = [];
        for (let i = 1; i <= contestCount; i++) {
            boxes.push(<div className={i === 1 ? classes.priorRoundOutlineSpacerHalf : classes.priorRoundOutlineSpacer} key={`${syecId}-prior-round-outline-half-top-${roundNumber}-box-${i}`} />);
            boxes.push(<div className={classes.priorRoundOutline} key={`${syecId}-prior-round-outline-${roundNumber}-box-${i}`} />);
            if (i === contestCount) {
                boxes.push(<div className={classes.priorRoundOutlineSpacerHalf} key={`${syecId}-prior-round-outline-half-bottom-${roundNumber}-box-${i}`} />);
            }
        }
        return boxes;
    }

    return (
        <div className={classes.allColumns}>
            {
                roundNumber !== 1 &&
                <div className={classes.nextRoundOutlineColumn}>
                    {getPriorRoundOutlines()}
                </div>
            }
            {
                roundNumber !== 1 &&
                <div className={classes.priorRoundOutlineColumn}>
                    {getNextRoundOutlines()}
                </div>
            }
            <div className={classes.roundColumn}>
                {getContestBoxes()}
            </div>
        </div>
    );

};