import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import * as contextStore from '../../redux/contextStore';
import * as recordStore from '../../redux/recordStore';
import * as queueStore from '../../redux/queueStore';
import * as authStore from '../../redux/authStore';
import * as resultDownloadStore from '../../redux/resultDownloadStore';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import CloudDownloadOutlineIcon from 'mdi-material-ui/CloudDownloadOutline';
import DownloadNetworkOutlineIcon from 'mdi-material-ui/DownloadNetworkOutline';
import PlayIcon from 'mdi-material-ui/Play';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    },
    selector: {
        width: '200px'
    },
    breadcrumbBar: {
        display: 'flex',
        alignItems: 'center',
        height: '45px'
    },
    breadcrumbIcons: {
        marginLeft: '24px'
    },
    downloading: {
        color: '#b7dfb9',
        backgroundColor: '#071107',
        border: '2px solid #4caf50',
        padding: '8px',
        margin: '2px'
    },
    hide: {
        display: 'none'
    }
}));

export const SelectedEventBreadcrumbs = props => {

    const classes = useStyles();
    const dispatch = useDispatch();
    
    const isLoggedIn = useSelector(state => authStore.selectors.selectIsLoggedIn(state));

    const series = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeries')).sort((a, b) => a.shortName > b.shortName ? 1 : -1);
    const selectedSeries = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeries'));
    const selectedSeriesId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeries'));
    const saveSelectedSeriesId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeries', id)), [dispatch]);

    const seriesClasses = useSelector(state => recordStore.selectors.selectRecords(state, 'rdtSeriesClass'));

    const seriesYears = useSelector(state => contextStore.selectors.selectYearsForSelectedSeries(state)).sort((a, b) => a.year > b.year ? -1 : 1);
    const selectedSeriesYear = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYear'));
    const selectedSeriesYearId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYear'));
    const saveSelectedSeriesYearId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYear', id)), [dispatch]);

    const seriesYearEvents = useSelector(state => contextStore.selectors.selectEventsForSelectedSeriesYear(state)).sort((a, b) => a.startDate > b.startDate ? 1 : -1);
    const selectedSeriesYearEvent = useSelector(state => recordStore.selectors.selectSelectedRecord(state, 'rdtSeriesYearEvent'));
    const selectedSeriesYearEventId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEvent'))
    const saveSelectedSeriesYearEventId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYearEvent', id)), [dispatch]);

    const seriesYearEventClasses = useSelector(state => contextStore.selectors.selectClassesForSelectedSeriesYearEvent(state)).sort((a, b) => a.classCode > b.classCode ? 1 : -1);
    const selectedSeriesYearEventClassId = useSelector(state => recordStore.selectors.selectSelectedId(state, 'rdtSeriesYearEventClass'));
    const saveSelectedSeriesYearEventClassId = useCallback(id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYearEventClass', id)), [dispatch]);

    const saveSelectedSeriesYearEventClassContestId = id => dispatch(recordStore.actionCreators.setSelectedId('rdtSeriesYearEventClassContest', id));

    const handleSeriesSelect = (e) => {
        saveSelectedSeriesId(e.target.value);
    }

    const handleSeriesYearSelect = (e) => {
        saveSelectedSeriesYearId(e.target.value);
    }

    const handleSeriesYearEventSelect = (e) => {
        saveSelectedSeriesYearEventId(e.target.value);
    }

    const handleSeriesYearEventClassSelect = (e) => {
        saveSelectedSeriesYearEventClassId(e.target.value);
        saveSelectedSeriesYearEventClassContestId('');
    }

    const queueEventProcessing = useCallback(
        () => dispatch(queueStore.actionCreators.queueProcessEvent(selectedSeriesYearEvent.seriesCode, selectedSeriesYearEvent.year, selectedSeriesYearEvent.eventCode)),
        [dispatch, selectedSeriesYearEvent]
    );

    const eventsDownloading = useSelector(state => resultDownloadStore.selectors.selectEventsDownloading(state));
    const getEventsDownloading = useCallback(() => dispatch(resultDownloadStore.actionCreators.getEventsDownloading()), [dispatch]);

    useEffect(() => {
        getEventsDownloading();
    }, [getEventsDownloading]);

    const addEventToDownload = useCallback(
        () => dispatch(resultDownloadStore.actionCreators.addEventToDownload(selectedSeriesYearEvent.id)),
        [dispatch, selectedSeriesYearEvent]
    );

    const removeEventToDownload = useCallback(
        () => dispatch(resultDownloadStore.actionCreators.removeEventToDownload(selectedSeriesYearEvent.id)),
        [dispatch, selectedSeriesYearEvent]
    );

    const toggleResultsAutoDownload = () => {
        if (eventsDownloading.some(x => x.seriesYearEventId === selectedSeriesYearEvent.id)) {
            removeEventToDownload();
        } else {
            addEventToDownload();
        }
    };

    const reloadData = useCallback(recordType => dispatch(recordStore.actionCreators.setNeedsReload(recordType)), [dispatch]);
    const testCommand = () => {
        reloadData('rdtSeries');
    }

    return (
        <Box className={clsx(classes.breadcrumbBar, { [classes.hide]: !isLoggedIn })}>
            <Breadcrumbs {...props}>
                <Select displayEmpty className={classes.selector} value={selectedSeriesId} onChange={handleSeriesSelect}>
                    <MenuItem value={''}><em>Series</em></MenuItem>
                    {series.map(x => <MenuItem key={x.id} value={x.id}>{x.shortName}</MenuItem>)}
                </Select>
                {selectedSeries &&
                    <Select displayEmpty className={classes.selector} value={selectedSeriesYearId} onChange={handleSeriesYearSelect}>
                        <MenuItem value={''}><em>Year</em></MenuItem>
                        {seriesYears.map(x => <MenuItem key={x.id} value={x.id}>{x.year}</MenuItem>)}
                    </Select>
                }
                {selectedSeriesYear &&
                    <Select displayEmpty className={classes.selector} value={selectedSeriesYearEventId} onChange={handleSeriesYearEventSelect}>
                        <MenuItem value={''}><em>Event</em></MenuItem>
                        {seriesYearEvents.map(x => <MenuItem key={x.id} value={x.id}>{x.shortName}</MenuItem>)}
                    </Select>
                }
                {selectedSeriesYearEvent &&
                    <Select displayEmpty className={classes.selector} value={selectedSeriesYearEventClassId} onChange={handleSeriesYearEventClassSelect}>
                        <MenuItem value={''}><em>Class</em></MenuItem>
                    {seriesYearEventClasses.map(syec => { return { ...syec, seriesClassShortName: seriesClasses.find(sc => sc.id === syec.seriesClassId).shortName }; }).sort((a, b) => a.seriesClassShortName > b.seriesClassShortName ? 1 : -1).map(x => <MenuItem key={x.id} value={x.id}>{x.seriesClassShortName}</MenuItem>)}
                    </Select>
                }
            </Breadcrumbs>
            {selectedSeries && selectedSeries.seriesCode.startsWith('nhra') ?
                <Box className={classes.breadcrumbIcons}>
                    <IconButton
                        disabled={!selectedSeriesYearEvent}
                        onClick={toggleResultsAutoDownload}
                        className={
                            clsx({ [classes.downloading]: selectedSeriesYearEvent && eventsDownloading.some(x => x.seriesYearEventId === selectedSeriesYearEvent.id) })
                        }>
                        <DownloadNetworkOutlineIcon />
                    </IconButton>
                    <IconButton disabled={!selectedSeriesYearEvent} onClick={queueEventProcessing}>
                        <CloudDownloadOutlineIcon />
                    </IconButton>
                    <IconButton onClick={testCommand} disabled>
                        <PlayIcon />
                    </IconButton>
                </Box> : []
            }
        </Box>
    );

}