export const recordTypes = [
    {
        recordTypeName: 'rdtSeries',
        urlEndpoint: 'series'
    },
    {
        recordTypeName: 'rdtSeriesClass',
        urlEndpoint: 'series-classes'
    },
    {
        recordTypeName: 'rdtSeriesDriver',
        urlEndpoint: 'series-drivers'
    },
    {
        recordTypeName: 'rdtSeriesYear',
        urlEndpoint: 'years'
    },
    {
        recordTypeName: 'rdtSeriesYearEvent',
        urlEndpoint: 'events'
    },
    {
        recordTypeName: 'rdtSeriesYearEventClass',
        urlEndpoint: 'event-classes'
    },
    {
        recordTypeName: 'rdtSeriesYearEventClassDriver',
        urlEndpoint: 'event-class-drivers'
    },
    {
        recordTypeName: 'rdtSeriesYearEventClassContest',
        urlEndpoint: 'contests'
    }
]