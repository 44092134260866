import React, { useEffect, useMemo, useState } from 'react';
//import clsx from 'clsx';
import { UserListPanel } from './UserListPanel';
import { UserInfoPanel } from './UserInfoPanel';
import { makeStyles } from '@material-ui/core/styles';
//import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
//import Toolbar from '@material-ui/core/Toolbar';
//import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import * as userStore from '../../redux/userStore';
import { useDispatch, useSelector } from 'react-redux';
//import RefreshIcon from '@material-ui/icons/Refresh';
import { useNavigate, useMatch } from '@reach/router';
import { PageContentPresenter } from '../container/PageContentPresenter';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexGrow: '1'
    },
    userList: {
        flexGrow: 1
    },
    userInfo: {
        marginLeft: '12px'
    }
}));

export const UserPage = () => {

    const classes = useStyles();

    const match = useMatch('/app/users/:userId');
    const userId = match?.userId ? match.userId : '';
    const navigate = useNavigate();

    const usersIsLoaded = useSelector(state => userStore.selectors.selectUsersIsLoaded(state));
    const isLoadErrorUsers = useSelector(state => userStore.selectors.selectIsLoadErrorUsers(state));
    const loadErrorMessageUsers = useSelector(state => userStore.selectors.selectLoadErrorMessageUsers(state));
    const usersIsLoading = useSelector(state => userStore.selectors.selectUsersIsLoading(state));
    const matchUserExists = useSelector(state => userStore.selectors.selectUserByIdExists(state, userId));
    const selectedUserId = useSelector(state => userStore.selectors.selectSelectedUserId(state));

    const dispatch = useDispatch();
    const refreshUsers = useMemo(() => () => dispatch(userStore.actionCreators.getUsers()), [dispatch]);
    const setSelectedUserId = useMemo(() => (selectedUserId) => dispatch(userStore.actionCreators.setSelectedUserId(selectedUserId)), [dispatch]);

    const [open, setOpen] = useState(false);
    const [prepareAlert, setPrepareAlert] = useState(false);

    const handleClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (usersIsLoading) {
            setPrepareAlert(true);
        }
        if (!usersIsLoading && prepareAlert) {
            setOpen(true);
            setPrepareAlert(false);
        }
    }, [usersIsLoading, prepareAlert]);

    useEffect(() => {
        if (!usersIsLoaded && !usersIsLoading) {
            refreshUsers();
        }
    }, [refreshUsers, usersIsLoaded, usersIsLoading]);

    useEffect(() => {
        if (match && match.userId && usersIsLoaded) {
            if (matchUserExists) {
                if (match.userId !== selectedUserId) {
                    setSelectedUserId(match.userId);
                }
            } else {
                navigate('/app/users', { replace: true });
            }
        }
    }, [match, usersIsLoaded, navigate, matchUserExists, selectedUserId, setSelectedUserId]);

    useEffect(() => {
        if ((!match || !match.userId) && usersIsLoaded && selectedUserId && selectedUserId !== '') {
            setSelectedUserId('');
        }
    }, [match, usersIsLoaded, selectedUserId, setSelectedUserId]);

    const getAlert = () => {
        if (!isLoadErrorUsers) {
            return (
                <Alert onClose={handleClose} severity='success'>Users loaded!</Alert>
            );
        } else if (isLoadErrorUsers) {
            return (
                <Alert elevation={6} variant='filled' onClose={handleClose} severity='error'>{`Error loading users: ${loadErrorMessageUsers}`}</Alert>
            );
        }
    }

    return (
        <PageContentPresenter showBreadcrumbs={false}>
            <Box className={classes.container}>
                <Box className={classes.userList}>
                    <UserListPanel />
                </Box>
                {selectedUserId &&
                    <Box className={classes.userInfo}>
                        <UserInfoPanel />
                    </Box>
                }
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    {getAlert()}
                </Snackbar>
            </Box>
        </PageContentPresenter>
    );
};