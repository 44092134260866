import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import UndoIcon from '@material-ui/icons/Undo';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    toolbar: {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        paddingLeft: 0,
        paddingRight: 0,
        minHeight: '48px'
    }
}));

export const ContestDetailToolbar = ({ isDirty, onSave }) => {
    const classes = useStyles();

const onSaveHandler = e => {
    e.stopPropagation();
    onSave();
}

    return (
        <Toolbar className={classes.toolbar}>
            <IconButton>
                <UndoIcon />
            </IconButton>
            <IconButton disabled={!isDirty} onClick={onSaveHandler}>
                <SaveIcon />
            </IconButton>
            <IconButton disabled>
                <DeleteIcon />
            </IconButton>
        </Toolbar>
    );
};
