import * as actionTypes from '../actionTypes';

const initialState = {
    isEventSelectionChanging: false,
    isUrlQueryChanging: false
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actionTypes.setIsEventSelectionChanging:
            return {
                ...state,
                isEventSelectionChanging: action.isEventSelectionChanging
            };

        case actionTypes.setIsUrlQueryChanging:
            return {
                ...state,
                isUrlQueryChanging: action.isUrlQueryChanging
            };

        default:
            return state;
    }
};